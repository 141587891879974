import React from 'react';
import logoWhite from '../images/Eureca_Logo_Branco.png';
import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, Typography, Divider, Link as MuiLink, List, ListItem, ListItemText, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  if (location.pathname.includes("dashboard")) {
    return null;
  }

  return (
    <Box component="footer" sx={{ pt: 4, bgcolor: '#0D0D0D', color: '#F5F5F5', position: 'relative' }}>
      <Container>
        <Grid container spacing={6} justifyContent="space-between">
          {/* Logo and Description */}
          <Grid item xs={12} md={5} lg={4}>
            <MuiLink href="/" sx={{ display: 'block', mb: 3 }}>
              <Box component="img"
                src={logoWhite}
                alt="logo"
                sx={{ height: 60 }}
              />
            </MuiLink>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Eureca é a sua plataforma para encontrar os melhores explicadores e recursos de estudo. Junte-se a nós para impulsionar seu aprendizado e sucesso.
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
              <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                <FacebookIcon />
              </IconButton>
              <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                <TwitterIcon />
              </IconButton>
              <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                <InstagramIcon />
              </IconButton>
              <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Links Section */}
          <Grid item xs={12} md={7} lg={6}>
            <Grid container spacing={4}>
              {/* Eureca Links */}
              <Grid item xs={6} sm={4}>
                <Typography variant="h6" gutterBottom color="primary">Eureca</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Contactos
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Encontrar Explicador
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Encontrar tutor
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Dicas de estudo
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>

              {/* Teaching Links */}
              <Grid item xs={6} sm={4}>
                <Typography variant="h6" gutterBottom color="primary">Ensinar</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Torne-se um explicador
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Preços
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                        Serviços
                      </MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 6, bgcolor: 'grey.800' }} />

        {/* Bottom footer */}
        <Grid container justifyContent="space-between" alignItems="center" textAlign={{ xs: 'center', md: 'left' }} sx={{ pb: 6 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" className='text-white'>
              Copyright ©{currentYear} Eureca. Todos os direitos reservados.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <List sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemText>
                  <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                    Termos de uso
                  </MuiLink>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <ListItemText>
                  <MuiLink href="index-11.html#" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>
                    Política e privacidade
                  </MuiLink>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;