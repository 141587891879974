import React from 'react';
import {headers,getDecodedToken} from '../util/utilities';
import {Link} from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Network from '../network/networkRequests';
import UserItems from'../modules/UserItems';
import {useMediaQuery,useTheme} from '@mui/material';
import {DialogTitle,Dialog,DialogContent,DialogContentText,DialogActions,Button,Badge} from '@mui/material';
import Messages from "../modules/Messages";
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';

const base_user_info = getDecodedToken();

function contains(a, obj) {
  var i = a.length;
  while (i--) {
     if (a[i].user?.email === obj) {
         return true;
     }
  }
  return false;
}

function Dashboard(props) {
  const {instructorList} = props;

    const db = getDatabase();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [invites, Setinvites] = React.useState([]);
    const [message, Setmessage] = React.useState([]);
    const location = useLocation();

    const firebaseSavePath = 'AlertCount/' + base_user_info.id;
    const  [isMine,setisMine] = React.useState(false);

    React.useEffect(()=>{
      if(location.pathname.includes("inbox"))
      setOpen(true);
    },[location]);

    const [open, setOpen] = React.useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
    
    React.useEffect(()=>{
      getenvites();
      set(ref(db, firebaseSavePath),{"count":0});
      setisMine(invites.length > 2? invites[invites.length-1].MsgFrom !== base_user_info.id:false);
    },[]);

    React.useEffect(()=>{
      setisMine(invites.length > 1 && invites[invites.length-1].MsgFrom !== base_user_info.id);
    },[invites]);
    
    const productsCheck = [];
   
    message?.forEach(item => {
      if (contains(productsCheck,item?.user?.email)) {
        // productsCheck[product.user.email]["qtd"] += 1;
      } else {
        productsCheck.push(item);
      }
    })

    const getenvites = async ()=>{
      await Network.getUsersInvites({}).then((response)=>{
          Setinvites(response.data);
      }).catch(error => {
        console.log(error);
      });
    };

    return (
        <>
        <main className='bg-light'>
          <section className="pt-0 bg-light">
            {/* <Submenu dropdownItems={dropdownItems}/> */}
            <div className={location.pathname.includes("inbox") && isMobile?"":"container"}>
            {/* {location.pathname.includes("inbox")??<style>{`body { overflow-y: hidden; }`}</style>} */}
              <div className="d-flex flex-lg-row flex-column gap-2">

              {/* {location.pathname.includes("inbox") && isMobile?<></>:<LeftManu profile={profile} simpleProfile={base_user_info}/>} */}
              <div className={"card card-body p-0 mt-3 border shadow-0"}>
              <div className=" border-0">
                  <div>
                  <div className="card-header  border-bottom d-flex justify-content-between align-items-center">
                      <h5 className="mb-0" style={{fontSize:isMobile?"13px":"17px"}}>Notificações </h5>
                      <div>
                      <Link to={"/dashboard/profile"}>
                        <Badge badgeContent={1} color="error">
                          <Button className="d-flex gap-2 mainTextColor p-0 align-items-center" >
                            <h5 className="mb-0 mainTextColor" style={{fontSize:isMobile?"12px":"14px", textTransform:"none"}}>Minha Conta</h5>
                            <Person2RoundedIcon/>
                          </Button>
                        </Badge>
                      </Link>
                      </div>
                  </div>
                  
                    {invites.length<= 0? <div className='text-dark w-100 text-center mt-4'>
                        <span className='p-4'>Não tem notificações!</span>
                        </div>:invites?.map((item,index)=>(<UserItems key={index} delected={true} item={item}  />))}
                  </div>
                </div>
              
              </div>
              </div>
            </div>
          </section>
        </main>

        <Dialog
          open={open}
          fullScreen={isMobile}
          // fullWidth="xs"
          onClose={handleClose}>
          <Messages instructors={instructorList??[]} onClose={()=>setOpen(false)} isMine={isMine}/>
        </Dialog>
        </>
    );
}

export default Dashboard;