import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentIcon from '@mui/icons-material/Payment';
import {Link} from "react-router-dom";

function AnalysisBanner(props) {
  const {base_user_info,handleOpenPayment} = props;

  return (
<Box
      sx={{
        display: 'flex',
        marginTop:"15px",
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#f2f0fb', // Tom suave de roxo claro
        border: '1px solid #6f42c1',
        flexDirection: { xs: 'column', sm: 'row' }, // Responsivo: Coluna em dispositivos pequenos
      }}
    >
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 2, sm: 0 } // Margem inferior em dispositivos pequenos
        }}
      >
        {Number(base_user_info.featured) == 1? <AvTimerRoundedIcon sx={{ fontSize: '3rem', color: '#6f42c1', mr: 2 }} />:
                <PaymentIcon sx={{ fontSize: '3rem', color: '#6f42c1', mr: 2 }} />}
      

      <div>
      <Typography variant="h6" component="strong" sx={{ color: '#4b2b83' }}>
         {Number(base_user_info.featured) == 1?" Seu pedido está em analise":"O seu pedido foi aceite"}
       </Typography>
      <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'left' }, color: '#5e4784' }}>
      {Number(base_user_info.featured) == 1?"Esta verificação pode levar entre 1 a três dias ou menos":""}
      </Typography>
        </div> 
      </Box>
      <Link to="/become-instructor">
      <Button 
        variant="contained" 
        style={{
          height: '50px',
          fontWeight: 'bold',
          transition: 'background 0.3s ease',
          '&:hover': {
            color: '#fff',
          },
        }}
        disableElevation
        onClick={()=>{
          // handleOpenPayment();
        }}
        className={Number(base_user_info.featured) == 1?'mainColor':'bg-success'}
        startIcon={Number(base_user_info.featured) == 1?<MailOutlineIcon />:<PaidIcon/>}
      >
        {Number(base_user_info.featured) == 1?"Contactar":"Terminar inscrição"}
        
      </Button>
      </Link>
    </Box>
  );
}

export default AnalysisBanner;