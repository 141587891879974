import React from 'react';
import '../css/style.css';

const CardShimmer = () => {
  return (
    <div className="col-sm-6 col-lg-4 col-xl-3 mb-2  card-shimmer">
      <div className="card-shimmer__avatar"></div>
      <div className="card-shimmer__content">
        <div className="card-shimmer__line"></div>
        <div className="card-shimmer__line"></div>
        <div className="card-shimmer__line"></div>
        <div className="card-shimmer__line"></div>
      </div>
    </div>
  );
};

export default CardShimmer;