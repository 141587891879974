import React,{useRef, useState,useEffect} from 'react';
import { Outlet, Link } from "react-router-dom";
import svg from '../images/element/02.svg';
import HashLoader from "react-spinners/HashLoader";
import Alert from '@mui/material/Alert';
import {Select,Button,TextField,MenuItem,FormControl,InputLabel,InputAdornment,IconButton,OutlinedInput} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

var axios = require('axios');

function SignUp(props) {
  const {setopenSignUp,fullScreen} = props;

  const email = useRef();
  const nome = useRef();
  const ultimoNome = useRef();
  const genero = useRef();
  const municipio = useRef();
  const provincia = useRef();
  const [stateIndex,setstateIndex] = useState(0);
  const telefone = useRef();
  const password = useRef();
  const Repassword = useRef();
  const [error,SetError] = useState({});
  const [loading,Setloading] = useState(false);
  const [payload,SetPayload] = useState({});
  const [showPassword, setShowPassword] = React.useState(false);

  const states = [
  'Bengo',
  ,'Benguela',
  ,'Bié',
  ,'Cabinda',
  ,'Cuando-Cubango',
  ,'Cunene',
  ,'Huambo',
  ,'Huíla',
  ,'Kwanza Sul',
  ,'Kwanza Norte',
  ,'Luanda',
  ,'Lunda Norte',
  ,'Lunda Sul',
  ,'Malanje',
  ,'Moxico',
  ,'Namibe',
  ,'Uíge',
  ,'Zaire'];
  
  const handleChange = (event) => {
    setstateIndex(event.target.value);
  };

  const handleLogin = () => {
  
    const data =  JSON.stringify({
      username: email.current.value,
      password: password.current.value,
    });
    
    var config = {
        method: 'POST',
        url: 'https://api.eureca.ao/v0.1/login',
        headers: {'Content-Type': 'application/json'},
        data:data
    };
    axios(config)
    .then(function (response) {
        if(response.data?.error){
          console.log(response.data);
        }else{
          localStorage.setItem("token",response.data.token);
          localStorage.setItem("refreshToken",response.data.refreshToken);
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  
}


const handleClickShowPassword = () => setShowPassword((show) => !show);

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

  const register = e =>{
        e.preventDefault();
        Setloading(true);
        var data = JSON.stringify({
          "email": email.current.value,
          "nome": nome.current.value,
          "ultimoNome": ultimoNome.current.value,
          "typeuser": "aluno",
          "genero": genero.current.value,
          "provincia": provincia.current?.value,
          "telefone": telefone.current.value,
          "senha": password.current.value
        });

        var config = {
          method: 'post',
          url: 'https://api.eureca.ao/v0.1/SignUp',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        axios(config)
        .then(function (response) {
          Setloading(false);
          if(response.data?.error){
            SetError(response.data?.error);
          }else{
            SetPayload(response.data);
            if(response.data?.success){
              handleLogin();
              setTimeout(function(){ window.location = "/"; }, 2000);
            }
          }
         
        })
        .catch(function (error) {
          SetError({error:error});
          console.log(error);
          Setloading(false);
        });
       
  }

  return (
          <section className="p-4" style={{minWidth: !fullScreen?"550px":""}}>
                <div className="col m-auto">
                      <h3>Inscreva-se!</h3>
                      <p className="lead mb-3 small">A um passo do sucesso.</p>
                      {/* Form START */}
                      <form onSubmit={register}>
                        <div className="mb-3 d-flex gap-3">
                          <TextField
                              ref={nome}
                              type="text"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Primeiro"
                              id="exampleInputEmail1"
                            />
                            </div>
                          <div className="mb-3 d-flex gap-3">
                          <TextField
                              ref={ultimoNome}
                              type="text"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Último nome"
                              id="exampleInputEmail1"
                            />
                        </div>
                        <div className="mb-3">
                          <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={email}
                              type="email"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="E-mail"
                              id="exampleInputEmail1"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex gap-3">
                          <FormControl className="TextField-group TextField-group-lg w-50">
                           <InputLabel id="demo-simple-select-label" style={{marginTop:"10px"}}>Genero</InputLabel>
                            <Select className="form-control border-0 bg-light rounded-end ps-1" ref={genero} value={genero.current?.value}>
                                <MenuItem value="Masculino">Masculino</MenuItem>
                                <MenuItem value="Femenino">Femenino</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl className="TextField-group TextField-group-lg w-50">
                           <InputLabel id="demo-simple-select-label" style={{marginTop:"10px"}}>Provincia/estado</InputLabel>
                            <Select className="form-control border-0 bg-light rounded-end ps-1" ref={provincia} value={provincia.current?.value}>
                              {states.map((e,index)=>(<MenuItem value={e}>{e}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="mb-3">
                          <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={telefone}
                              type="tel"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Telefone"
                              id="exampleInputEmail1"
                            />
                          </div>
                        </div>
                       
                        <div className="mb-3">
                          <FormControl className="TextField-group  TextField-group-lg w-100" variant="outlined">
                          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                            <OutlinedInput
                              ref={password}
                              type={showPassword ? 'text' : 'password'}
                              className=" border-0 bg-light rounded-end ps-1"
                              label="Palavra passe*"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                        
                        <div className="mb-3">
                        <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={Repassword}
                              type="password"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Confirmar Palavra passe*"
                              id="inputPassword6"
                            />
                          </div>
                        </div>
                       
                        {/* Check box */}
                        <div className="mb-3">
                         {error.error?<Alert severity="error">{error.error}</Alert>:""}
                        
                          <div className="form-check small">
                            Ao inscrever-se, concorda com os
                              <a href="#">  termos de serviço</a>
                          </div>
                        </div>
                        {/* Button */}
                        <div className="align-items-center mt-0">
                          <div className="d-grid">
                          {payload?.success?<Alert severity="success">{payload.success}</Alert>: <Button className="btn mainColor mb-0 d-flex justify-content-center" style={{height:"50px"}} type="submit">
                            {loading? <HashLoader color="#ffffff" size={30} loading/>:"Criar Conta"}</Button>}
                           
                          </div>
                        </div>
                      </form>
                      {/* Form END */}
                      <div className="mt-4 text-center d-flex align-items-center justify-content-center">
                        <span>Já tem uma conta?</span>
                        <Link className="p-3 rounded-5" to="/login">
                            <div type="button" className='p-2 mainTextColor small'>Entrar</div> 
                        </Link>
                      </div>
                </div>
          </section>
    );
}

export default SignUp;