import React from 'react';
import svg from '../images/element/30.svg';

function Services(props) {
    return (
    <section className="position-relative bg-info">
      {/* Svg image decoration */}
      <div className="position-absolute bottom-0 end-0">
        <img src={svg} className="h-300px" alt="" />
      </div>
      <div className="container">
      <div className="text-center mb-5">
            <h2>“O informal também te forma”</h2>
            <p className="mb-0">
            A melhor e maior plataforma nacional, com recursos necessários para garantir o sucesso no aprendizado.
            </p>
          </div>
        <div className="row g-4">
          {/* Title */}
       
          {/* Feature item */}
          <div className="col-sm-6 col-lg-4 ">
            <div className="card card-body bg-white p-4 h-100">
              {/* Svg image decoration */}
              <figure>
              <svg
                  className="fill-warning"
                  width="60.77px"
                  height="52.15px"
                  viewBox="0 0 60.77 52.15"
                  style={{ enableBackground: "new 0 0 60.77 52.15" }}
                >
                  <path d="M49.74,35.57c-3.93,0-7.13-3.2-7.13-7.13c0-3.93,3.2-7.13,7.13-7.13s7.13,3.2,7.13,7.13 C56.87,32.37,53.67,35.57,49.74,35.57z M49.74,23c-3,0-5.44,2.44-5.44,5.44c0,3,2.44,5.44,5.44,5.44c3,0,5.44-2.44,5.44-5.44 C55.19,25.44,52.74,23,49.74,23z" />
                  <path d="M49.74,38.55c-5.57,0-10.11-4.53-10.11-10.11c0-5.57,4.53-10.11,10.11-10.11c5.57,0,10.11,4.53,10.11,10.11 C59.85,34.01,55.32,38.55,49.74,38.55z M49.74,20.02c-4.64,0-8.42,3.78-8.42,8.42c0,4.64,3.78,8.42,8.42,8.42 c4.64,0,8.42-3.78,8.42-8.42C58.17,23.8,54.39,20.02,49.74,20.02z" />
                  <path d="M49.74,50.93c-1.52,0-2.76-1.24-2.76-2.76V37.51c0-0.25,0.11-0.49,0.31-0.65c0.2-0.16,0.46-0.22,0.7-0.17 c1.15,0.24,2.33,0.24,3.48,0c0.25-0.05,0.51,0.01,0.7,0.17c0.2,0.16,0.31,0.4,0.31,0.65v10.66C52.5,49.69,51.26,50.93,49.74,50.93z  M48.67,38.49v9.68c0,0.59,0.48,1.07,1.07,1.07c0.59,0,1.07-0.48,1.07-1.07v-9.68C50.1,38.57,49.39,38.57,48.67,38.49z" />
                  <path d="M55.2,21.79c-0.47,0-0.84-0.38-0.84-0.84V9.1c0-0.53-0.44-0.97-0.97-0.97h-1.13v11.15c0,0.47-0.38,0.84-0.84,0.84 s-0.84-0.38-0.84-0.84V7.29c0-0.47,0.38-0.84,0.84-0.84h1.97c1.46,0,2.65,1.19,2.65,2.66v11.85C56.04,21.42,55.66,21.79,55.2,21.79 z" />
                  <path d="M45.66,37.6H5.24c-1.46,0-2.65-1.19-2.65-2.65V9.1c0-1.46,1.19-2.66,2.65-2.66H7.2c0.47,0,0.84,0.38,0.84,0.84v25.35 c0,0.47-0.38,0.84-0.84,0.84s-0.84-0.38-0.84-0.84V8.13H5.24c-0.53,0-0.97,0.44-0.97,0.97v25.85c0,0.53,0.44,0.97,0.97,0.97h40.42 c0.47,0,0.84,0.38,0.84,0.84C46.5,37.23,46.13,37.6,45.66,37.6z" />
                  <path d="M29.31,33.47c-0.47,0-0.84-0.38-0.84-0.84V5.72c0-0.47,0.38-0.84,0.84-0.84c0.47,0,0.84,0.38,0.84,0.84v26.91 C30.15,33.1,29.78,33.47,29.31,33.47z" />
                  <path d="M31.4,37.6h-4.18c-1.13,0-2.05-0.92-2.05-2.05c0-1.15-0.93-2.08-2.08-2.08H7.2c-0.47,0-0.84-0.38-0.84-0.84V4.51 c0-0.47,0.38-0.84,0.84-0.84h12.2c2.67,0,5.35,0.27,7.97,0.8l1.93,0.39l1.93-0.39c2.62-0.53,5.3-0.8,7.97-0.8h12.21 c0.47,0,0.84,0.38,0.84,0.84v14.77c0,0.47-0.38,0.84-0.84,0.84s-0.84-0.38-0.84-0.84V5.35H39.21c-2.56,0-5.13,0.26-7.63,0.77 l-2.1,0.43c-0.11,0.02-0.22,0.02-0.33,0l-2.1-0.43c-2.51-0.51-5.08-0.77-7.64-0.77H8.05v26.44h15.04c2.07,0,3.76,1.69,3.76,3.76 c0,0.2,0.16,0.37,0.37,0.37h4.18c0.2,0,0.37-0.16,0.37-0.37c0-2.07,1.69-3.76,3.76-3.76h5.95c0.47,0,0.84,0.38,0.84,0.84 c0,0.47-0.38,0.84-0.84,0.84h-5.95c-1.15,0-2.08,0.93-2.08,2.08C33.45,36.68,32.53,37.6,31.4,37.6z" />
                  <path d="M23.35,10.56H12.01c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C24.19,10.18,23.82,10.56,23.35,10.56z" />
                  <path d="M23.35,15.25H12.01c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C24.19,14.87,23.82,15.25,23.35,15.25z" />
                  <path d="M23.35,19.93H12.01c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C24.19,19.56,23.82,19.93,23.35,19.93z" />
                  <path d="M23.35,24.62H12.01c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C24.19,24.24,23.82,24.62,23.35,24.62z" />
                  <path d="M46.61,10.56H35.27c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C47.45,10.18,47.08,10.56,46.61,10.56z" />
                  <path d="M46.61,15.25H35.27c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.34c0.47,0,0.84,0.38,0.84,0.84 C47.45,14.87,47.08,15.25,46.61,15.25z" />
                  <path d="M49.28,19.93H35.27c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h14.01c0.47,0,0.84,0.38,0.84,0.84 C50.13,19.56,49.75,19.93,49.28,19.93z" />
                  <path d="M41.74,24.62h-6.47c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h6.47c0.47,0,0.84,0.38,0.84,0.84 C42.58,24.24,42.2,24.62,41.74,24.62z" />
                </svg>
              </figure>
              <h5>Descubra</h5>
              <p className="mb-0">
              Facilmente no nosso universo de explicadores / tutores, o que melhor se enquadra na sua necessidade.
              </p>
            </div>
          </div>
          {/* Feature item */}
          <div className="col-sm-6 col-lg-4">
            <div className="card card-body bg-white p-4 h-100">
              {/* Svg icon */}
              <figure>
                <svg
                  className="fill-orange"
                  width="54.22px"
                  height="58.19px"
                  viewBox="0 0 54.22 58.19"
                  style={{ enableBackground: "new 0 0 54.22 58.19" }}
                >
                  <path d="M27.11,9.75c-2.32,0-4.21-1.89-4.21-4.21c0-2.32,1.89-4.21,4.21-4.21c2.32,0,4.21,1.89,4.21,4.21 C31.32,7.86,29.43,9.75,27.11,9.75z M27.11,3.05c-1.37,0-2.49,1.12-2.49,2.49s1.12,2.49,2.49,2.49s2.49-1.12,2.49-2.49 S28.48,3.05,27.11,3.05z" />
                  <path d="M31.61,27.21H22.6c-0.48,0-0.86-0.39-0.86-0.86c0-0.48,0.39-0.86,0.86-0.86h9.01c0.48,0,0.86,0.39,0.86,0.86 C32.48,26.82,32.09,27.21,31.61,27.21z" />
                  <path d="M22.31,27.21c-1.51,0-1.68-0.03-1.75-0.05c-1.14-0.22-1.96-1.25-1.96-2.45v-8.63c0-2.79,2.09-5.14,4.87-5.46 c0.85-0.1,2.08-0.14,3.66-0.12c1.55-0.02,2.79,0.02,3.64,0.12c2.78,0.32,4.87,2.66,4.87,5.46v8.63c0,1.2-0.83,2.23-1.96,2.45 c-0.08,0.02-0.26,0.05-2.05,0.05c-0.48,0-0.86-0.39-0.86-0.86v-8.72c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v7.86 c0.44,0,0.81-0.01,0.91-0.02c0.28-0.05,0.52-0.38,0.52-0.75v-8.63c0-1.92-1.44-3.53-3.34-3.75c-0.78-0.09-1.94-0.13-3.44-0.11 c-1.52-0.02-2.68,0.02-3.46,0.11c-1.9,0.22-3.34,1.83-3.34,3.75v8.63c0,0.37,0.24,0.7,0.56,0.76c0.07,0.01,0.43,0.01,0.86,0.01 v-7.86c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v8.72c0,0.48-0.38,0.86-0.86,0.86C22.5,27.21,22.4,27.21,22.31,27.21 z" />
                  <path d="M13.31,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86s0.86,0.39,0.86,0.86v4.11 C14.17,56.37,13.79,56.75,13.31,56.75z" />
                  <path d="M9.51,43.26c-2.27,0-4.12-1.85-4.12-4.12s1.85-4.12,4.12-4.12c2.27,0,4.12,1.85,4.12,4.12S11.78,43.26,9.51,43.26z  M9.51,36.75c-1.32,0-2.39,1.07-2.39,2.39c0,1.32,1.07,2.39,2.39,2.39c1.32,0,2.39-1.07,2.39-2.39 C11.9,37.83,10.83,36.75,9.51,36.75z" />
                  <path d="M5.7,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v4.11 C6.57,56.37,6.18,56.75,5.7,56.75z" />
                  <path d="M30.91,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v4.11 C31.77,56.37,31.39,56.75,30.91,56.75z" />
                  <path d="M27.11,43.26c-2.27,0-4.12-1.85-4.12-4.12s1.85-4.12,4.12-4.12c2.27,0,4.12,1.85,4.12,4.12S29.38,43.26,27.11,43.26z  M27.11,36.75c-1.32,0-2.39,1.07-2.39,2.39c0,1.32,1.07,2.39,2.39,2.39s2.39-1.07,2.39-2.39C29.5,37.83,28.43,36.75,27.11,36.75z" />
                  <path d="M23.31,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v4.11 C24.17,56.37,23.78,56.75,23.31,56.75z" />
                  <path d="M48.51,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v4.11 C49.38,56.37,48.99,56.75,48.51,56.75z" />
                  <path d="M44.71,43.26c-2.27,0-4.12-1.85-4.12-4.12s1.85-4.12,4.12-4.12s4.12,1.85,4.12,4.12S46.98,43.26,44.71,43.26z M44.71,36.75 c-1.32,0-2.39,1.07-2.39,2.39c0,1.32,1.07,2.39,2.39,2.39c1.32,0,2.39-1.07,2.39-2.39C47.1,37.83,46.03,36.75,44.71,36.75z" />
                  <path d="M40.91,56.75c-0.48,0-0.86-0.39-0.86-0.86v-4.11c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v4.11 C41.77,56.37,41.38,56.75,40.91,56.75z" />
                  <path d="M51.78,31.77H2.44C1.64,31.77,1,31.13,1,30.34v-3.42c0-0.79,0.64-1.44,1.44-1.44h49.35c0.79,0,1.44,0.64,1.44,1.44v3.42 C53.22,31.13,52.57,31.77,51.78,31.77z M2.72,30.05H51.5v-2.84H2.72V30.05z" />
                  <path d="M3.01,38.35c-0.48,0-0.86-0.39-0.86-0.86v-6.58c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v6.58 C3.87,37.97,3.48,38.35,3.01,38.35z" />
                  <path d="M51.21,38.35c-0.48,0-0.86-0.39-0.86-0.86v-6.58c0-0.48,0.39-0.86,0.86-0.86c0.48,0,0.86,0.39,0.86,0.86v6.58 C52.07,37.97,51.69,38.35,51.21,38.35z" />
                  <path d="M16.95,56.75H2.07c-0.48,0-0.86-0.39-0.86-0.86v-6.5c0-2.73,2.04-5.02,4.75-5.33c0.47-0.06,0.9,0.28,0.95,0.76 s-0.29,0.9-0.76,0.95c-1.84,0.21-3.23,1.77-3.23,3.62v5.63h13.16V49.4c0-1.85-1.39-3.41-3.23-3.62c-0.47-0.05-0.81-0.48-0.76-0.95 c0.05-0.47,0.49-0.81,0.95-0.76c2.71,0.31,4.75,2.6,4.75,5.33v6.5C17.81,56.37,17.42,56.75,16.95,56.75z" />
                  <path d="M34.55,56.75H19.67c-0.48,0-0.86-0.39-0.86-0.86v-6.5c0-2.73,2.04-5.02,4.75-5.33c0.47-0.06,0.9,0.28,0.95,0.76 c0.05,0.47-0.29,0.9-0.76,0.95c-1.84,0.21-3.23,1.77-3.23,3.62v5.63h13.16V49.4c0-1.85-1.39-3.41-3.23-3.62 c-0.47-0.05-0.81-0.48-0.76-0.95c0.05-0.47,0.49-0.81,0.95-0.76c2.71,0.31,4.75,2.6,4.75,5.33v6.5 C35.41,56.37,35.02,56.75,34.55,56.75z" />
                  <path d="M52.15,56.75H37.27c-0.48,0-0.86-0.39-0.86-0.86v-6.5c0-2.73,2.04-5.02,4.75-5.33c0.47-0.06,0.9,0.28,0.95,0.76 s-0.29,0.9-0.76,0.95c-1.84,0.21-3.23,1.77-3.23,3.62v5.63h13.15V49.4c0-1.85-1.39-3.41-3.23-3.62c-0.47-0.05-0.81-0.48-0.76-0.95 c0.05-0.47,0.49-0.81,0.95-0.76c2.71,0.31,4.75,2.6,4.75,5.33v6.5C53.01,56.37,52.63,56.75,52.15,56.75z" />
                </svg>
              </figure>
              <h5>Organize</h5>
              <p className="mb-0">
              O seu plano de estudo presencial ou online (24h/24h), com o explicador / tutor escolhido.
              </p>
            </div>
          </div>
          {/* Feature item */}
          <div className="col-sm-6 col-lg-4">
            <div className="card card-body bg-white p-4 h-100">
              {/* Svg image decoration */}
              <figure>
                <svg
                  className="fill-purple"
                  width="54.22px"
                  height="52.86px"
                  viewBox="0 0 54.22 52.86"
                  style={{ enableBackground: "new 0 0 54.22 52.86" }}
                >
                  <path d="M32.48,50.83H15.21c-0.45,0-0.82-0.37-0.82-0.82v-2.73c0-1.21-0.91-2.24-2.11-2.39L9.9,44.58 c-2.02-0.26-3.54-1.99-3.54-4.03V34.8l-2.94,0.02c-0.59,0-1.11-0.3-1.41-0.81C1.72,33.5,1.72,32.9,2,32.39l2.63-4.63 c1.46-2.58,2.24-5.51,2.23-8.47c0-4.95,2.25-9.62,6.02-12.49c3.77-2.87,8.47-4.09,13.22-3.44c0.67,0.09,1.35,0.22,2,0.39 c0.44,0.11,0.7,0.56,0.59,1c-0.11,0.44-0.57,0.71-1,0.59c-0.59-0.15-1.2-0.27-1.81-0.36c-4.32-0.59-8.58,0.52-12,3.12 c-3.36,2.56-5.37,6.74-5.37,11.18c0,3.25-0.85,6.46-2.45,9.28L3.44,33.2l2.94-0.03C7.28,33.17,8,33.9,8,34.8v5.76 c0,1.21,0.91,2.24,2.11,2.39l2.38,0.31c2.02,0.26,3.54,1.99,3.54,4.03v1.9h15.62l-0.02-9.33c0-2.73,1.15-5.4,3.16-7.35 c0.66-0.64,1.27-1.33,1.81-2.07c0.27-0.37,0.78-0.45,1.15-0.18c0.37,0.27,0.45,0.79,0.18,1.15c-0.6,0.81-1.27,1.58-1.99,2.28 c-1.72,1.66-2.67,3.85-2.66,6.16l0.02,10.15c0,0.22-0.09,0.43-0.24,0.58C32.91,50.75,32.7,50.83,32.48,50.83z" />
                  <path d="M43.22,50.83H2.18c-0.45,0-0.82-0.37-0.82-0.82c0-0.45,0.37-0.82,0.82-0.82h41.04c0.45,0,0.82,0.37,0.82,0.82 C44.05,50.47,43.68,50.83,43.22,50.83z" />
                  <path d="M43.81,27.69H29.1c-0.45,0-0.82-0.37-0.82-0.82c0-0.45,0.37-0.82,0.82-0.82h14.72c0.45,0,0.82,0.37,0.82,0.82 C44.64,27.32,44.27,27.69,43.81,27.69z" />
                  <path d="M45.79,18.58H31.84c-0.45,0-0.82-0.37-0.82-0.82c0-0.45,0.37-0.82,0.82-0.82h13.95c0.45,0,0.82,0.37,0.82,0.82 C46.61,18.21,46.24,18.58,45.79,18.58z" />
                  <path d="M43.81,9.46H26.68c-0.45,0-0.82-0.37-0.82-0.82c0-0.45,0.37-0.82,0.82-0.82h17.13c0.45,0,0.82,0.37,0.82,0.82 C44.64,9.09,44.27,9.46,43.81,9.46z" />
                  <path d="M46.59,12.23c-1.98,0-3.6-1.61-3.6-3.6s1.61-3.6,3.6-3.6s3.6,1.61,3.6,3.6S48.57,12.23,46.59,12.23z M46.59,6.69 c-1.07,0-1.95,0.87-1.95,1.95c0,1.07,0.87,1.95,1.95,1.95c1.07,0,1.95-0.87,1.95-1.95C48.54,7.56,47.66,6.69,46.59,6.69z" />
                  <path d="M48.56,21.35c-1.98,0-3.6-1.61-3.6-3.6s1.61-3.6,3.6-3.6s3.6,1.61,3.6,3.6S50.55,21.35,48.56,21.35z M48.56,15.8 c-1.07,0-1.95,0.87-1.95,1.95c0,1.07,0.87,1.95,1.95,1.95c1.07,0,1.95-0.87,1.95-1.95C50.51,16.68,49.64,15.8,48.56,15.8z" />
                  <path d="M46.59,30.46c-1.98,0-3.6-1.61-3.6-3.6s1.61-3.6,3.6-3.6s3.6,1.61,3.6,3.6S48.57,30.46,46.59,30.46z M46.59,24.92 c-1.07,0-1.95,0.87-1.95,1.95s0.87,1.95,1.95,1.95c1.07,0,1.95-0.87,1.95-1.95S47.66,24.92,46.59,24.92z" />
                </svg>
              </figure>
              <h5>Aproveite</h5>
              <p className="mb-0">
                 Ao máximo todas as vantagens que o ensino informal lhe proporciona, e, sinta o prazer de aprender “descontraído”.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
}

export default Services;