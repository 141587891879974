import React,{useState} from 'react';
import {headers} from '../util/utilities';
import HashLoader from "react-spinners/HashLoader";
import { useLocation } from "react-router-dom"
import {Slider, Chip,Button,useMediaQuery,Radio,FormControlLabel,Switch,Grid} from '@mui/material';
import CardShimmer from './cardShimmer';
import CardTutors from "../modules/CardTutors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Network from '../network/networkRequests';
import { useAlert } from '../modules/AlertContext';
import {getDecodedToken} from '../util/utilities';

var axios = require('axios');
function valuetext(value) {
    return `${value}°C`;
}

const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function InstructorList(props) {
    const {instructors,skills,porcent} = props;
    const base_user_info = getDecodedToken();
    const { showAlert } = useAlert();
    const [instrList,setinstrList] = useState(instructors);
    const [selectedValue, setSelectedValue] = useState('');
    const [open, setOpen] = React.useState(false);

    const [inviteSend, setinviteSend] = React.useState(false);

    const p = React.useRef();
    const [input,setInput] = React.useState(null);
    const [loading,setloading] = React.useState(true);
    const [filters,setfilters] = React.useState(['Tipo de aula','Distância','Preços','Nível','Tempo de resposta']);
    const [educationLevels, setEducationLevels] = useState([
        "Todos os níveis",
        "1º ciclo",
        "2º ciclo",
        "3º ciclo",
        "10º ano",
        "11º ano",
        "12º ano",
        "Ensino superior",
        "Formação para adultos",
        "Mestrado",
        "Doutoramento",
        "MBA",
        "Pós-Graduação"
      ]);

      const [localeselection, setlocaleselection] = useState([
        "Procurar perto de mim",
        "Presencial",
        "Online"
      ]);

    const [Activefilter,setActivefilter] = React.useState(null);

    const [searchText,SetSearchText] = React.useState('');

    const toggle = (porcent > 0.4);
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const update=(val)=>{
        SetSearchText(p.current.value);
    }

    const applyText=()=>{
        setInput(searchText);
    }
    const handleFilter=(index)=>{
        setActivefilter(index);
        setOpen(true);
    }

    const handleClose = () => {
        changeElementByIndex();
        setOpen(false);
    };

    const changeElementByIndex = () => {
        if( Activefilter !== 0 &&  Activefilter !== 3 &&  Activefilter !== 4)
            return;
        // Create a copy of the array
        const updatedLevels = [...filters];
        // Update the element at the specified index
        updatedLevels[Activefilter] = selectedValue;
        // Update the state
        setfilters(updatedLevels);
    };

    const handleChangeChoose = (event) => {
        setSelectedValue(event.target.value);
      };
    
      const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChangeChoose,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });

    const getAllUsers = async ()=>{
        const response = await Network.getUsersData({});

        if(response.data){
            setinstrList(response.data);
        }

        setloading(false);
      };

    const [preco, setPreco] = React.useState([2000, 3000]);

    const handleChange = (event, newValue) => {
        setPreco(newValue);
    };
    React.useEffect(()=>{
        getAllUsers();

        if(params.get("p") != null)
            setInput(params.get("p"));

    },[])
 
    async function sugestsHundle(showAlert, setinviteSend)  {
        showAlert('Estamos a processar o pedido', 'success');
        setinviteSend(true);
        await Network.sendRequest('post', '/sugestSearch', {"userId": base_user_info.id,"sugests":input});
    }    

    return (
        <main className='bg-white mt-2 p-2'>
            <section className="py-0 mt-3">
            <div className="container">
                    <h3 className="text-dark">{window.location.pathname === "/get-tutors"? "Os melhores tutores de Angola!":configs.teachers[0].value}</h3>
                    <form className="border rounded-5 p-1 mt-4"  style={{maxWidth:isMobile?"":"40vw"}}>
                        <div className="input-group input-borderless">
                            <input
                            className="form-control me-1"
                            type="search"
                            placeholder="Procurar um Explicador"
                            ref={p}
                            onChange={update}
                            />
                            <button type="button" className="btn mainBtn mb-0 rounded-5" onClick={applyText}>
                            {!isMobile && <span>Pesquisar</span>} <i className="fas fa-search" /> 
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            <section className="pt-4">
            <div className="container">
                    {/* Search option START */}
                    <div className="d-flex gap-3 mb-4 align-items-center" style={{overflowX:"auto"}}>
                        {filters.map((ite,key)=>(<Chip key={key} label={ite} onClick={()=>handleFilter(key)} style={{cursor:"pointer", background:"#fff", border:"1px solid #aaa"}} className={key === Activefilter? "mainBtn":""}/>))}
                    </div>

                    <div className="row g-4 justify-content-center" style={{padding:isMobile? "12px":""}}>
                        {loading?[...Array(10).keys()]?.map((item) => (
                            <div key={item} className='col-sm-6 col-lg-4 col-xl-3'><CardShimmer/></div>
                        )):""}

                        {
                        input == null || input == "" ? (
                            instrList?.map((item, index) =><CardTutors key={index} usercard={item} />)
                        ) : (
                            instrList
                            .filter(
                                (item) =>
                                (item?.nome + " " + item?.ultimoNome)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            )
                            .length === 0 ? (
                                <div className='container mt-4 d-flex justify-content-center flex-column align-items-center border border rounded-5 p-3'>
                                    <p>Nemhum resultado encontrado para <strong>{input}</strong></p>
                                <div className='d-flex justify-content-center flex-column align-items-center mt-4'>
                                    <p>Deseja uma pesquisa avancada para a {input}?</p>
                                    <Button disabled={inviteSend} className={inviteSend?'rounded-5  p-3':'rounded-5 mainBtn p-3'} onClick={async ()=>{
                                        await sugestsHundle(showAlert, setinviteSend);
                                    }}>Sim Porfavor</Button>
                                </div>
                                </div>
                            ) : (
                                instrList
                                .filter(
                                    (item) =>
                                    (item.nome + " " + item.ultimoNome)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                )
                                .map((item, index) => <CardTutors key={index} item={item} />)
                            )
                        )
                        }
                </div>

                {/* Instructor list END */}
                {/* Pagination START */}
                <nav
                className="mt-4 d-flex justify-content-center"
                aria-label="navigation"
                >
                    {loading?
                 <HashLoader color="#831caf" size={30} loading/>: <Button disabled={inviteSend} className=' rounded-5 border mainBtn p-3 mt-4'>{configs?.teachers[1].value}</Button>}
             
                </nav>
                {/* Pagination END */}
            </div>
            </section>

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}>
                <DialogContent className='p-3'>
                    <div>
                        <div style={{overflowY:"auto", maxHeight:"200px", minWidth:fullScreen?"80vw":"30vw"}}>
                            {
                                Activefilter !== 0 &&  Activefilter !== 3 &&  Activefilter !== 4 &&  (
                                    <div className='text-danger text-center mt-4'>Sem filtros para esta categoria, tente mais tarde.</div>
                                )
                            }
                        {Activefilter === 0 && <div className='d-flex flex-column'>
                            {localeselection.map((item,key)=>(<FormControlLabel
                                value={key === 0?'':item}
                                control={<Radio {...controlProps(key === 0?'':item)} color="secondary"/>}
                                label={item}
                                labelPlacement="End"
                            />))}
                        </div>}
                        {Activefilter === 3 && <div className='d-flex flex-column'>
                            {educationLevels.map((item,key)=>(<FormControlLabel
                                value={key === 0?'':item}
                                control={<Radio {...controlProps(key === 0?'':item)} color="secondary"/>}
                                label={item}
                                labelPlacement="End"
                            />))}
                        </div>}
                        {Activefilter === 4 && <div className='d-flex justify-content-between align-items-center'>
                            <strong>
                            Ver apenas os professores que respondem no dia
                            </strong>
                            <Switch label="" onChange={(e)=>{setSelectedValue(e.target.checked?"No dia":"Tempo de Resposta")}} color="secondary" />
                        </div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className='border'>
                <Button autoFocus onClick={handleClose} className='rounded-5 text-dark p-3' style={{fontSize:"12px", width:"100px"}}>Eliminar</Button>
                <Button onClick={handleClose} autoFocus className='mainBtn rounded-5 p-3' style={{fontSize:"12px", width:"100px"}}>Aplicar</Button>
                </DialogActions>
            </Dialog>
        </main>
    );
}

export default InstructorList;
