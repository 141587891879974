import React,{useEffect, useState} from 'react';
import avatarProfile from '../images/avatar/05.jpg';
import { useParams } from 'react-router-dom';
import {Link} from "react-router-dom";
import Switch from '@mui/material/Switch';
import {UserItems,Submenu,getDecodedToken} from '../util/utilities';
import {headers} from '../util/utilities';
import HashLoader from "react-spinners/HashLoader";
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Network from '../network/networkRequests';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import {
  Box,
  Card,
  Chip,
  CardContent,
  CardActions,
  Container,
  Typography,
  Grid,
  Avatar,
  Button,
  Paper,
  IconButton,
  Rating,
  useTheme,
  useMediaQuery
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ContactDialog from '../modules/contactDialog';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import Cookies from 'js-cookie';

var CurrencyFormat = require('react-currency-format');

var md5 = require('md5');

const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function InstructorSingle(props) {
  
    //const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
    const date = React.useRef();
    const hour = React.useRef();
    const subject = React.useRef();
    const typeenvite = React.useRef();
    const subsubject = React.useRef();
    const base_user_info = getDecodedToken();

    const {id} = useParams();
    const single = props.instructors.find(k=>k.id==id);
    const [userSingle, setusersingle] = useState({});
    const [notificationCount,setnotificationCount] = useState(0);
 
    const [error,SetError] = React.useState({});
    const [loading,Setloading] = React.useState(false);
    const [aulaOnline,SetaulaOnline] = React.useState(true);
    const [payload,SetPayload] = React.useState({});
    var profileImage = single?.profileImage??'https://ui-avatars.com/api/?name='+single?.nome;
    const [selectedDate, setSelectedDate] = React.useState("");
    const [subjets, setsubjets] = React.useState([]);
    const [showFixedButton, setShowFixedButton] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [rantList, setRantList] = React.useState([]);
    
    

    const [openContactar, setopenContactar] = useState(false);
    
    useEffect(() => {
          window.scrollTo(0, 0);

          getRequestApp();
          if(single)
          onValue(ref(getDatabase(), 'inviteAlert/'+md5(single)), (snapshot) => {
            const dataResponse = snapshot.val();
            setnotificationCount(dataResponse?.count??0);
          });
          getRatingComments();
    }, []);

  // Effect to add window scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position.
      const currentScrollY = window.scrollY;

      // Determine if the fixed button should be shown based on the scroll position.
      // You can adjust the `scrollPositionToShowButton` to the position you want.
      const scrollPositionToShowButton = 300; // Example value.

      if (currentScrollY > scrollPositionToShowButton) {
        setShowFixedButton(true);
      } else {
        setShowFixedButton(false);
      }
    };

    // Add the event listener when the component mounts.
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts.
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const getRatingComments= async ()=>{
    const cachedData = getApiDataFromCookie("getRatingComments"+id);
    if (cachedData) {
      setRantList(cachedData);
      return;
    }

    await Network.sendRequest('get','/get/rating/'+id,{}).then(response => {
      setRantList(response.data);
      setApiDataInCookie("getRatingComments"+id, response.data);
    }).catch(error=>{
      console.log(error);
    });
  }


    const getRequestApp= async ()=>{
      const cachedData = getApiDataFromCookie("getRequestApp"+id);
      if (cachedData) {
        setusersingle(cachedData);
        setsubjets(cachedData.skills);
        return;
      }

      await Network.getRequestApply(id).then(response => {
        setusersingle(response.data);
        setsubjets(response.data?.skills);
        setApiDataInCookie("getRequestApp"+id, response.data);
      }).catch(error=>{
        console.log(error);
      });
    }


     const handleDateChange = (e) => {
      const selectedDate = e.target.value;
      const day = new Date(selectedDate).getDay();
      if (day === 6 || day === 0) {
        // Excludes Saturdays and Sundays
        alert("Porfavor selecione um dia de semana.");
      } else {
        // Allows future dates
        setSelectedDate(selectedDate);
      }
    };   
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(base_user_info.role == "NONE"){
            props.setOpen(true);
            return;
        }
        var data = JSON.stringify({
          "MsgFrom":base_user_info?.email,
          "MsgTo": single?.email,
          "isOpen": "0",
          "archived": "0",
          "message": "Pedido de aula",
          "details": {
            "date":date.current.value,
            "hour": hour.current.value,
            "subject": subject.current.value,
            "type":typeenvite.current.value,
            "subsubject":subsubject.current.value
          },
        });
        Setloading(true);
        
        // set(ref(db, 'inviteAlert/'+md5(single)),{
        //   "count":notificationCount+1
        // })
        // .then(() => {
        //   console.log("Data saved successfully!");
        // })
        // .catch((error) => {
        //   console.log(error);
        //   // The write failed...
        // });

        await Network.submitRequestequestApply(data).then((response)=>{
          if(response.data?.error){
            SetError(response.data.error);
          }else{
            SetPayload(response.data);
            setTimeout(function(){
                window.location.href = '/dashboard/invits';
            }, 400); 
           
          }
          Setloading(false);
        }).catch(error => {
          console.log(error);
          SetError(error);
          Setloading(false);
        });
      }

      const setApiDataInCookie = (key, data) => {
        const jsonData = JSON.stringify(data);
        Cookies.set(key, jsonData, { expires: 188 / 86400 });
      };

      const getApiDataFromCookie = (key) => {
        const jsonData = Cookies.get(key);
        return jsonData ? JSON.parse(jsonData) : null;
      };

    return (
      <div>
       <Container maxWidth="lg" className={isMobile?'p-0':''}>
      <Grid container spacing={isMobile?0:3}>
        
        {/* Coluna de perfil do usuário */}
        <Grid item xs={12} md={4} className={isMobile?'bd-masthead':''} style={{ borderBottomRightRadius:"30px", borderBottomLeftRadius:"30px"}}>
          <Box sx={{ position: 'sticky', top: 80 }}>
            <Paper  elevation={!isMobile?1:0} sx={{ padding: 3}} style={{background:isMobile?"transparent":"#fff"}} className='rounded-5 w-100'>
              <Box display="flex" flexDirection="column" alignItems="center">
                <div>
                  <Avatar src={single?.profileImage?single?.profileImage:"https://ui-avatars.com/api/?name="+single?.nome} sx={{ width: 140, height: 140, mb: 1 }} className='rounded-5' style={{border:isMobile?"8px solid #fff":""}} />
                  <div className='favoritePainel'>
                      <IconButton className='rounded-6 shadow mainTextColor'><ShareIcon/></IconButton>
                  </div>
                </div>
              
                {/* <Rating value={2} readOnly /> */}
                <Typography variant="body2"  style={{fontWeight:"bold"}}>{userSingle?.user?.name}</Typography>
                
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }} >
                <StarRateRoundedIcon style={{color:"orange", fontSize:"25px", position:"relative", top:"-2px"}}/>
                <small style={{marginRight:"5px"}}><strong>{Math.round(userSingle?.user?.averageRating??0)}</strong></small>
                ({userSingle?.user?.ratingCount??0} avaliações)</Typography>
                <div className={isMobile?'shadow d-flex bg-white rounded-5 gap-2 w-100 justify-content-between align-items-center':'d-flex bg-white rounded-5 gap-3 w-100 justify-content-center'} style={{flexDirection:isMobile?'row':'column', padding:isMobile?"10px":"6px"}}>
                    <div className='d-flex justify-content-between'  style={{flexDirection:!isMobile?'row-reverse':'column', padding:"5px"}}>
                      <Typography variant="body3" className='text-center' style={{fontWeight:"bold"}}>
                        <CurrencyFormat value={userSingle.price??0} displayType={'text'} format="#.### AOA"/>
                      </Typography>
                      <Typography style={{fontSize:"12px", textAlign:"center"}} variant="body3">{configs?.request[1].value}</Typography>
                    </div>
                    <div className='d-flex justify-content-between' style={{flexDirection:!isMobile?'row-reverse':'column',padding:"5px"}}>
                      <Typography variant="body3" className='text-center'  style={{fontWeight:"bold"}}>{userSingle?.responseTime}h</Typography>
                      <Typography style={{fontSize:"12px", textAlign:"center"}} variant="body3">{configs?.request[2].value}</Typography>
                    </div>
                    <div className='d-flex justify-content-between' style={{flexDirection:!isMobile?'row-reverse':'column',padding:"5px"}}>
                      <Typography variant="body3" className='text-center' style={{fontWeight:"bold"}}>+{userSingle?.studantCount}</Typography>
                      <Typography style={{fontSize:"12px", textAlign:"center"}} variant="body3">{configs?.request[3].value}</Typography>
                  </div>
                </div>
                {!isMobile && <Button variant="contained" elevation={0}  onClick={()=>setopenContactar(true)} fullWidth color="primary" sx={{ mt: 2 }} className='rounded-5 p-3 btnColor'>
                {configs?.request[4].value}
                </Button>}
              </Box>
            </Paper>
          </Box>
        </Grid>

        {/* Conteúdo principal */}
        <Grid item xs={12} md={8} className='p-4'>
        <Grid item style={{height:"20px"}}></Grid>
         {isMobile?
          <div className='d-flex gap-2 overflow-hidden'> {subjets?.map((item,index)=>(<Chip className='btnColor' label={item}/>))}</div>:
          <Grid container spacing={1}>
          {subjets?.map((item,index)=>(<Grid item><Chip className='btnColor' label={item}/></Grid>))}
      </Grid>}

          <Grid item style={{height:"20px"}}></Grid>

          <Typography variant="body3"  className='h4'>
            {userSingle?.shortDescription}
          </Typography>
          
          
          <Grid item style={{height:"30px"}}></Grid>
          <Grid item>
          <Typography variant="body3"  className='h6'>Local das aulas</Typography>
          </Grid>
          <Grid item style={{height:"20px"}}></Grid>
          
          <Grid container flexDirection={"column"} className='p-4 border rounded-5'>
            <Typography variant="body3" className='h6'>Em casa do(a) {userSingle.name}:</Typography>
            <Typography variant="body3">{userSingle?.province}</Typography>
            
            <Typography variant="body3" className='h6'>Em casa ou num local público :</Typography>
            <Typography variant="body3">Distância até 20 km</Typography>

            <Typography variant="body3" className='h6'>Online/Presencial</Typography>
          </Grid>

          <Grid item style={{height:"20px"}}></Grid>
          <Grid item>
            <Typography variant="body3"  className='h6'>Sobre {userSingle.name}</Typography>
          </Grid>
          <Grid item style={{height:"20px"}}></Grid>
          <p>{userSingle?.description}</p>
          <Grid item style={{height:"20px"}}></Grid>
          <Grid container justifyContent={"space-between"} className='w-100 pb-3'>
            <Grid item className='h6'>Avaliações</Grid>
            <Grid item className='h5'><Chip label={'Like 50'}/></Grid>
          </Grid>
         <Grid container style={{overflowY:"auto"}}>
          {rantList.map((item,index)=>(
            <Grid container flexDirection={"column"} className='p-4 border rounded-5 mb-2' key={index}>
              <div className='d-flex gap-2 align-items-center justify-content-between pb-4'>
               <div className='d-flex gap-2 align-items-center'>
                 <Avatar src={item?.userProfileImage?item?.userProfileImage:"https://ui-avatars.com/api/?name="+item?.userName} sx={{ width: 40, height: 40, mb: 1 }} className='m-2' />
                <Typography variant="body3" className='h6'>{item.userName}</Typography>
               </div>
               <div ><Chip label={<span className='d-flex gap-1 align-items-center'><StarOutlineIcon/><strong>{item.val}</strong></span>}/></div>
              </div>
              <Typography variant="body3"> <Typography variant="body3" className='h6'>{parseInt(item.val) >= 3?"Perfeito!":"Moderado"}</Typography> {item.comment}</Typography>
            </Grid>
         ))}
         
         </Grid>
        </Grid>
        

      </Grid>
      {isMobile && (
        <CardActions sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} className='bg-white p-3 d-flex flex-column'>
          {showFixedButton && <Box className='d-flex justify-content-between w-100'>
              <div className='d-flex justify-content-start'>
              <Avatar src={single?.profileImage?single?.profileImage:"https://ui-avatars.com/api/?name="+single?.nome} sx={{ width: 40, height: 40, mb: 1 }} className='m-2' />
               <div>
                    <div className='d-flex'>
                      <Rating value={Math.round(userSingle?.user?.averageRating??0)} readOnly />
                      <Typography variant="body2" color="textSecondary">{userSingle?.ratingCount} avaliações</Typography>
                    </div>
                    <div>
                      <Typography variant="body3" className='text-center h6'>
                      <CurrencyFormat value={userSingle.price??0} displayType={'text'} format="#.### AOA"/>/h
                      </Typography>
                    </div>
               </div>
              </div>
              <Button>
                <Chip label={<FavoriteBorderIcon/>}/>
              </Button>
               </Box>}
          <Button fullWidth variant="contained" color="primary" onClick={()=>setopenContactar(true)} className='rounded-5 p-3 btnColor'>
            Contactar
          </Button>
        </CardActions>
      )}
    </Container>
    <ContactDialog open={openContactar} setopenContactar={()=>setopenContactar(false)} single={single} subjets={subjets}/>
    </div>
    );
}

export default InstructorSingle;