import React from 'react';
import svg from '../images/element/02.svg';
import { Outlet, Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

import CloseIcon from '@mui/icons-material/Close';
import {DialogTitle,Alert,useMediaQuery,useTheme,Button} from '@mui/material';
import Network from '../network/networkRequests';
const configs =   JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function Forgotpassword(props) {
  // const {onClosePopup,setopenSingup} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const email = React.useRef();
  const password = React.useRef();
  const password2 = React.useRef();

  const code = React.useRef();

  const [error,SetError] = React.useState("");
  const [payload,SetPayload] = React.useState({});
  const [loading,Seloading] = React.useState(false);

  const [step,Setstep] = React.useState(0);


  const handleSubmit = async(e) => {
      e.preventDefault();

      if(code.current?.value != undefined)
        localStorage.setItem("code",code.current?.value);

      try{
        if(!email.current?.value && step === 0) return;
      Seloading(true);
      const data =  JSON.stringify({
        email: email.current?.value,
        password: password.current?.value,
        password2: password2.current?.value,
        code:code.current?.value??localStorage.getItem("code"),
        step:step
      });
      
      const response = await Network.sendRequest("post","/auth/newpassword",data);
      
      if(response.statusText === "OK"){
        if(response.data?.error){
          console.log(response.data);
          SetError(response.data);
        }else{
          SetError("");
          SetPayload(response.data);
          Setstep(step+1);
          // localStorage.setItem("token",response.data.token);
          // localStorage.setItem("refreshToken",response.data.refreshToken);
          if(response?.data?.result  === "success"){
             window.location.href = '/login';
             localStorage.removeItem("code");
          }
        }
     
      
      }else{
        console.log(response.data?.error);
        SetError(response.data?.error);
      }
      if(step === 0)
        email.current.value ="";

      }catch(e){
        SetError(e);
      }

      Seloading(false);
      
  }

  return(
        <main style={{minWidth:"30vw"}}>
           <div className="drawer-header border-bottom p-3 d-flex justify-content-between">
          {/* <h4 className="drawer-title" id="drawer-1-title">Drawer Title</h4> */}
          {/* <Link className="p-3 rounded-5" to="/">Aplicar como explicador</Link>   */}
          <div><p className="fs-9 mt-3">Recuperar palavra-passe</p></div>
          <button className={'rounded-4 border-0'} style={{width:"50px", height:"50px"}} >
            <CloseIcon/>
          </button>
      </div>
          <div className="container p-5">
                    {/* Form START */}
                    <form onSubmit={handleSubmit}>
                    

                      {
                        step === 0?<>
                        <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="bi bi-envelope-fill" />
                          </span>
                          <input
                            ref={email}
                            type="email"
                            className="form-control border-0 bg-light rounded-end ps-0"
                            placeholder="Seu email"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <label htmlFor="" className='mt-2'>Receberá um codigo de restauro (00:30)</label>
                        <div id="passwordHelpBlock" className="form-text">
                          {error && <Alert severity="error">{error.error}</Alert>}
                        </div>
                        </div>
                        </>:
                        step === 1?<>
                        <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="bi bi-envelope-fill" />
                          </span>
                          <input
                            ref={code}
                            type="code"
                            className="form-control border-0 bg-light rounded-end ps-0"
                            placeholder="Código de restauro"
                          />
                        </div>
                        <div id="passwordHelpBlock" className="form-text">
                          {error && <Alert severity="error">{error.error}</Alert>}
                        </div>
                        <label htmlFor="" className='mt-2'>Verifica se recebeu o codigo na sua caixa de correio</label>
                        </div>
                        </>:<>
                        <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fas fa-lock" />
                          </span>
                          <input
                             ref={password}
                            type="password"
                            className="form-control border-0 bg-light rounded-end ps-0"
                            placeholder="Nova palavra-passe"
                            id="inputPassword5"
                          />
                        </div>
                        <div id="passwordHelpBlock" className="form-text">
                          {error && <Alert severity="error">{error.error}</Alert>}
                        
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="input-group input-group-lg">
                          <span className="input-group-text bg-light rounded-start border-0 text-secondary px-3">
                            <i className="fas fa-lock" />
                          </span>
                          <input
                             ref={password2}
                            type="password"
                            className="form-control border-0 bg-light rounded-end ps-0"
                            placeholder="Confirmar palavra-passe"
                            id="inputPassword5"
                          />
                        </div>
                        <div id="passwordHelpBlock" className="form-text">
                          {error && <Alert severity="error">{error.error}</Alert>}
                        </div>
                      </div>
                        </>
                      }
                      {/* Check box
                      <div className="mb-4 d-flex justify-content-between mb-4">
                        <div className="text-primary-hover">
                          <Link to="/auth/forgot-password" className="text-secondary">
                            <u>{configs?.Forgotpassword[2].value}</u>
                          </Link>
                        </div>
                      </div> */}
                      {/* Button */}
                      <div className="align-items-center mt-0">
                        <div className="d-grid">
                        {payload?.token?<Alert severity="success">Autenticacao bem sucedida...</Alert>:
                        <Button className="btn mainColor  rounded-5 mb-0 text-center d-flex justify-content-center align-items-center" style={{height:"50px", fontWeight:"bold", fontSize:"16px"}} type="submit">{loading? <HashLoader color="#ffffff" size={30} loading/>:
                        step==0?"Recuperar":step===1?"Confirmar":"Enviar"}</Button>}
                        </div>
                      </div>
                    </form>
                    {/* Form END */}
                   
                    {/* <div className="mt-4 text-center">
                      <span>Não tem uma conta? <button  className='p-2 text-primary small border-0 bg-white' onClick={()=>{
                        // setopenSingup(true);
                      }}>Inscreva-se aqui</button></span>
                    </div> */}
                  </div>
      </main>
      
    );
}

export default Forgotpassword;