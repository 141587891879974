import React, { useState,useEffect } from 'react';
import videoImage from '../images/about/04.jpg';
import Generaltips from '../modules/generaltips';
import Childrentips from '../modules/childrentips';
import EscalatorWarningRoundedIcon from '@mui/icons-material/EscalatorWarningRounded';
import { useParams,Link } from 'react-router-dom';
import SosRoundedIcon from '@mui/icons-material/SosRounded';
const configs = JSON.parse(window.localStorage.getItem(`configs${window.localStorage.getItem("version")}`));
function StudyTips() {

  const { page } = useParams();
  
  // const RenderTabs = () => {
  //   if (tabSelected === 1) {
  //     return <Childrentips key={1} />;
  //   } else if (tabSelected === 2) {
  //     return <Generaltips key={2} />;
  //   } else {
  //     return <MainContent />;
  //   }
  // };

  // const MainContent = () => (
  //   <section>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col">
  //           <h5>Melhorar seu aprendizado e retenção</h5>
  //           <div className="row">
  //             {renderTips()}
  //           </div>
  //           {renderSVGSection()}
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // );

  const renderTips = () => (
    <>
      {[
        [
          'Crie um cronograma de estudos: planeje o seu tempo de estudo e siga-o. Isso pode ajudá-lo a manter o foco e evitar a procrastinação.',
          'Elimine as distrações: desligue o seu celular, feche as abas desnecessárias no seu computador e encontre um espaço de estudo tranquilo.',
          'Faça pausas: faça pequenas pausas a cada hora mais ou menos para recarregar a sua mente e evitar a fadiga.',
        ],
        [
          'Use estratégias de aprendizagem ativa: pratique técnicas de aprendizagem ativa, como fazer anotações, resumir informações e ensinar alguém o que você aprendeu.',
          'Mantenha-se organizado: mantenha seus materiais de estudo e anotações organizados de uma maneira que funcione para você. Use ferramentas como pastas, arquivos e notas adesivas para se manter no caminho certo.',
          'Use mnemônicos: mnemônicos são auxiliares de memória que podem ajudá-lo a lembrar informações importantes. Crie acrônimos ou rimas para ajudá-lo a lembrar conceitos-chave.',
        ],
        [
          'Teste a si mesmo: teste seus conhecimentos regularmente usando questionários ou cartões de estudo. Isso pode ajudá-lo a identificar áreas onde você precisa melhorar.',
          'Durma o suficiente: uma boa noite de sono é essencial para a aprendizagem e a memória. Aponte para pelo menos 7-8 horas de sono por noite.',
          'Coma uma dieta saudável: uma dieta equilibrada pode ajudar a melhorar a função do seu cérebro e aumentar sua capacidade de aprendizado.',
          'Mantenha-se motivado: mantenha seus objetivos em mente e lembre-se por que você está estudando. Celebre seus sucessos e mantenha uma atitude positiva mesmo quando enfrentar contratempos.',
        ],
      ].map((tips, index) => (
        <div className="col-lg-4" key={index}>
          <ul className="list-group list-group-borderless">
            {tips.map((tip, tipIndex) => (
              <li className="list-group-item h6 fw-normal d-flex" key={tipIndex}>
                <i className="bi bi-patch-check-fill text-success me-2" />
                {tip}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );

  const renderSVGSection = () => (
    <section className="bg-light position-relative">
      <div className="container position-relative">
        <div className="row justify-content-between align-items-center my-5">
          <div className="col-lg-5 position-relative">
            <h2 className="h1">Descubra as melhores dicas de estudo para você</h2>
          </div>
          <div className="col-lg-5 position-relative mt-4 mt-lg-0">
            <img src={videoImage} className="border border-5 border-white rounded-2" alt="Video Thumbnail" />
            <div className="position-absolute top-50 start-50 translate-middle">
              <a
                href="https://www.youtube.com/embed/tXHviS-4ygo"
                className="btn text-danger btn-round btn-white-shadow btn-lg mb-0"
                data-glightbox=""
                data-gallery="video-tour"
              >
                <i className="fas fa-play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <main>
      <section className="bg-light">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-10 mx-auto text-center position-relative">
              <h1 className="display-7">{configs.help[0].value}</h1>
            </div>
            <div className="m-auto w-70 d-flex flex-column align-items-center gap-3">
              <form className="border rounded-5 p-1 w-100 bg-white">
                <div className="input-group input-borderless">
                  <input
                    className="form-control me-1"
                    type="search"
                    placeholder="Procurar um Explicador"
                    style={{ height: '48px' }}
                  />
                  <button type="button" className="btn mainBtn mb-0 rounded-5">
                    <i className="fas fa-search" />
                  </button>
                </div>
              </form>
              <p className="mb-0">{configs.help[1].value}</p>
            </div>
            <div className="mt-3 d-flex justify-content-between text-center">
              <Link to="/study-help/general" className="col-sm-6 col-md-3"  style={{textDecoration:"none"}}>
                <div className="card card-body card-border-hover p-0" >
                  <span className="p-3">
                    <h2>
                      <i className="fas fa-exclamation-circle transition-base mainTextColor" />
                    </h2>
                    <h6 className="mb-0">Estudantes em geral</h6>
                  </span>
                </div>
              </Link>
              <Link to="/study-help/father" className="col-sm-6 col-md-3" style={{textDecoration:"none"}}>
                <div className="card card-body card-border-hover p-0">
                  <span className="p-3">
                    <h2>
                      <EscalatorWarningRoundedIcon className="mainTextColor" style={{ fontSize: '1em' }} />
                    </h2>
                    <h6 className="mb-0">Encarregados de educação</h6>
                  </span>
                </div>
              </Link>
              <div className="col-sm-6 col-md-3" style={{textDecoration:"none"}}>
                <div className="card card-body card-border-hover p-0 bg-danger">
                  <span className="p-3">
                    <h2>
                      <SosRoundedIcon className="text-white" style={{ fontSize: '1em' }} />
                    </h2>
                    <h6 className="mb-0 text-white">Precisa de ajuda?</h6>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container mt-4 mb-4'>
        <h5>Dicas - Recentes</h5>
      </div>
      {page === 'general'? <Generaltips key={2} />: <Childrentips key={1} />}
      <br />
      <br />
    </main>
  );
}

export default StudyTips;
