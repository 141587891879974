import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import PricingCardDemo from './PricingCardDemo'
import spressImage from '../images/express.png'
import { Divider, makeStyles } from '@material-ui/core';
import spressTransferencia from '../images/tranferencia.png'


import PaymentTypes from './paymentTypes'

function Payment(props) {

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });
    
    const [paymentsTyps,SetpaymentsTyps] = React.useState([
        {
            id:1,
            name:"Mtx Express",
            Icon:spressImage,
            active:true
        },
        {
            id:2,
            name:"Transferencia",
            Icon:"imageUrl",
            active:true
        },
        {
            id:3,
            name:"PayPal",
            Icon:"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png",
            active:true
        }
    ]);

    return (
        <div>
           <Dialog
                  open={true}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={props.handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>Selecione o melhor plano para si.</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    
                     {/* <div className='d-flex justify-content-between'>
                        {plans.map((item,index)=>(<PricingCardDemo key={index} planDetail={item}/>))}
                     </div> */}
                     <div className='mt-3 mb-3'>Selecione o de pagamento</div>
                    <div className='mt-4 d-flex gap-4 justify-content-center'>
                    {paymentsTyps.map((item,index)=>(<PaymentTypes key={index} detail={item}/>))}
                    </div>
                    {/* <Divider variant="middle" className='mt-2 mb-2' />
                    adicionar detalhes de cartao de cfredito */}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={props.handleClose}>Disagree</Button> */}
                    <Button className='mainColor' onClick={props.handleClose}>Terminar</Button>
                  </DialogActions>
                </Dialog> 
        </div>
    );
}

export default Payment;