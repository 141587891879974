import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #ddd',
    borderRadius: '8px',
  },
  header: {
    textAlign: 'center',
    paddingBottom: '0px',
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  name: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: theme.palette.text.secondary,
  },
  benefits: {
    marginTop: theme.spacing(2),
  },
  additionalInfo: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  button: {
    height: '50px',
    fontWeight: 'bold',
    transition: 'background 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
    },
  },
  popularTag: {
    position: 'absolute',
    top: 10,
    left: 10,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    padding: '4px 12px',
    borderRadius: '16px',
    fontSize: '0.875rem',
  },
}));

export const PricingCardDemo = React.memo(function PricingCard(props) {
  const { setpay, planDetail, selected, handleOpenPayment } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ border: planDetail.isPopular ? `2px solid ${planDetail.color}` : '1px solid #ddd', position: 'relative' }}>
      {planDetail.isPopular && (
        <div className={classes.popularTag}>Popular</div>
      )}
      <CardHeader
        title={
          <div className='d-flex flex-column align-items-center mt-4'>
            <Typography className={classes.price}>{planDetail.price}.00Kzs</Typography>
            <Typography className={classes.name}>{planDetail.name}</Typography>
          </div>
        }
        className={classes.header}
      />
      <CardContent>
        <Divider variant="middle" />
        <div className={classes.benefits}>
          {planDetail?.benefits?.map((item, key) => (
            <Typography key={key} align="left">{item}</Typography>
          ))}
        </div>
        <div className={classes.additionalInfo}>
          <Typography align="left"><strong>Duração:</strong> {planDetail.duration}</Typography>
          <Typography align="left"><strong>Suporte:</strong> {planDetail.support}</Typography>
          <Typography align="left"><strong>Acesso:</strong> {planDetail.access}</Typography>
        </div>
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={classes.action}>
        <Button
          variant="contained"
          className={classes.button}
          fullWidth
          onClick={() => {
            handleOpenPayment();
            setpay(planDetail.id);
          }}
          style={{
            color: planDetail.id !== selected ? "#fff" : "#000",
            backgroundColor: planDetail.id !== selected ? planDetail.color : "#fff",
          }}
        >
          Escolher
        </Button>
      </CardActions>
    </Card>
  );
});

export default PricingCardDemo;
