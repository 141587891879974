
import './App.css';
import './css/style.css';
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/tiny-slider/tiny-slider.css";
import "./vendor/glightbox/css/glightbox.css";
import { BrowserRouter, Routes, Route,Link,useLocation } from "react-router-dom";
import {headers,Util} from './util/utilities';
import Header from './views/header';
import Footer from './views/Footer';
import Main from './views/Main';
import InstructorList from './views/InstructorList';
import React,{useEffect} from 'react';
import InstructorSingle from './views/InstructorSingle';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import BecameInstructor from './views/BecameInstructor';
import SignUp  from './views/SignUp';
import { AlertProvider } from './modules/AlertContext';
import StudyTips from './views/StudyTips';
import { useScrollPercentage } from 'react-scroll-percentage';
import ApplyInstructor from './views/applyInstructor';
import logo from './images/logo.png';
import {getDecodedToken} from './util/utilities';
import {DialogTitle,Dialog,useMediaQuery,useTheme,Button,DialogContent,DialogActions,Rating} from '@mui/material';
import Drawer from "./modules/Drawer";
import { initializeApp } from "firebase/app";
import AccountManagment from './modules/AccountManagment';
import Network from './network/networkRequests';
import Alert from './modules/Alert';
import PopupRating from './modules/PopupRating';
import Cookies from 'js-cookie';
const firebaseConfig = {
  apiKey: "AIzaSyAZoclRzoTI7vnPnJJQkJHFvluBbWRtBy8",
  authDomain: "eureca-7f61b.firebaseapp.com",
  databaseURL: "https://eureca-7f61b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eureca-7f61b",
  storageBucket: "eureca-7f61b.appspot.com",
  messagingSenderId: "645746760374",
  appId: "1:645746760374:web:e5b49feb3850a9c9236417",
  measurementId: "G-SN90THBVZ2"
};

if(localStorage.getItem("MAIN_URL") == null)
localStorage.setItem("MAIN_URL","https://api.eureca.ao/v0.1");

export default function App() {
  const [loading,setloading] = React.useState(false);
  const [showFooter, setFooter] = React.useState(false);
  const [open, setOpen] = React.useState(window.location.pathname === "/login")
  const [openSingup, setopenSingup] = React.useState(window.location.pathname === "/singup")

  useEffect(()=>{
    getConfigs();
  },[]);


  useEffect(()=>{
    Anonimuos();
    instructor();

    if(userOnline == 0){
      counterOnlineUsers();
      getRatingComments();
    }

    const updateActivityInterval = setInterval(() => {
      updateActivityFunc();
      counterOnlineUsers();
    }, 40000);
    
    return () => {
      clearInterval(updateActivityInterval);
    };
  },[]);


  useEffect(()=>{
    const paths = ["/get-tutors", "/become-instructor", "/study-help/general","/tutors"];
    setFooter(!paths.includes(window.location.pathname));
    setOpen(window.location.pathname === "/login")
    setopenSingup(window.location.pathname === "/singup");
  },[window.location.pathname]);


  const app = initializeApp(firebaseConfig);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSingUp = () => {
    setopenSingup(false);
  };


  function hasJWT(){
    return localStorage.getItem("token")?true:false;
  }
  const base_user_info = getDecodedToken();


  const [instructorList, SetInstructor] = React.useState([]);
  const [blibkNumber, SetblibkNumber] = React.useState();
  const [openDraw, SetDraw] = React.useState(false);
  const [subjects,Setsubjects] = React.useState([]);
  const [userOnline,SetUserOnline] = React.useState(0);

  const [userCount,SetuserCount] = React.useState(0);
  const [leftRating,setleftRating] = React.useState({});
  const [rantList, setRantList] = React.useState([]);
  
  const [expandRating,setexpandRating] = React.useState(false);

  const [rating,SetRating] = React.useState(0);
  const [ratingComment,setratingComment] = React.useState('');


  const [referencia, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  })


  const getConfigs = async () => {
    try {
      const response = await Network.sendRequest('get', '/configs/getall', {});
      if (!response || response.data.version === undefined) return;
  
      const currentVersion = window.localStorage.getItem('version');
      const storedConfigs = window.localStorage.getItem(`configs${response.data.version}`);
  
      if (storedConfigs !== null && currentVersion === response.data.version) {
        setloading(false);
        return;
      }
  
      window.localStorage.setItem('version', response.data.version);
      window.localStorage.setItem(`configs${response.data.version}`, JSON.stringify(response.data));
  
      // Assuming setConfigs is a state setter to update the local component state
      // setConfigs(response.data);
      setloading(false);
    } catch (error) {
      console.error('Failed to fetch configs:', error);
      setloading(false);
    }
  };

  
  // const getConfigs = async ()=>{
  //     const response =  await Network.sendRequest('get', '/configs/getall', {});
  //     if(!response) return;
     
  //     if(response.data.version == undefined) return;

  //     if(window.localStorage.getItem("configs"+response.data.version) != null){
  //       setloading(true);
  //       return;
  //     }

  //     window.localStorage.setItem("version",response.data.version);
  //     window.localStorage.setItem("configs"+response.data.version,JSON.stringify(response.data));
  //     window.location.reload();
  // }
  const Anonimuos = async ()=>{
      if(hasJWT())
        return;

      var data = JSON.stringify({
        "username": "anonimius@any.com",
        "password": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9"
      });
      
      const response =  await Network.sendRequest('post', '/login', data);
        if(response.data?.error){
          console.log("ERROR",response.data);
        }else{
          localStorage.setItem("token",response.data.token);
          localStorage.setItem("refreshToken",response.data.refreshToken);
          window.location.href = '/';
        }
  };

  const instructor = async()=>{
    const cachedData = getApiDataFromCookie("tutorsList");
    if (cachedData) {
      SetInstructor(cachedData);
      getallsubjects();
      return;
    }

    await Network.getUsersData({}).then(response => {
      if(response.data.error != undefined){
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }else{
        SetInstructor(response.data);
        setApiDataInCookie("tutorsList", response.data);
        localStorage.removeItem("subjs")
        getallsubjects();
      }
    }).catch(error=>{
      console.log(error);
    });
  };
  const getallsubjects = async () => {

    if (localStorage.getItem("subjs") != null) {
      console.log(localStorage.key("subjs"))
      Setsubjects(JSON.parse(localStorage.getItem("subjs")));
      return;
    }
  
    // If no cached data, fetch from the API
    try {
      const response = await Network.sendRequest('get', '/subjects/getsubjects', {});
      const subjectsData = response.data;

      Setsubjects(subjectsData);
      localStorage.setItem("subjs", JSON.stringify(subjectsData));
    } catch (error) {
      console.log("Failed to fetch subjects:", error);
    }
  };
  
  
  const getRatingComments= async ()=>{
    const cachedData = getApiDataFromCookie("ratings");
    if (cachedData) {
      setRantList(cachedData);
      return;
    }

    await Network.sendRequest('get','/get/ratings',{}).then(response => {
      if(response.data?.error) return;
      setRantList(response.data);
      setApiDataInCookie("ratings", response.data);
    }).catch(error=>{
      console.log(error);
    });
  }

  const updateActivityFunc = async()=>{
     await Network.sendRequest('post', '/updateactivity', {"userId": base_user_info.email});
  }

  const counterOnlineUsers= async()=>{
      try{
        const response =  await Network.sendRequest('get', '/findallonline', {});
        if(response)
          SetUserOnline(response.data.totalOnlineUsers);
    
        const response2 =  await Network.sendRequest('get', '/findallusers', {});
        if(response2)
          SetuserCount(response2.data.totalUsers);
    
        const response3 =  await Network.sendRequest('get', '/findlefrating', {});
        if(response3)
          setleftRating(response3.data);
      }catch(e){
        console.log("ERROR",e);
      }

  }

  const handleRating= async (e)=>{
    if(e.target.value < 1) return;

    setexpandRating(true);
    SetRating(e.target.value);
  }

  const handleComment= async (e)=>{
    if(e.target.value < 1) return;

    setratingComment(e.target.value);
  }


  const submitRating =async()=>{
    if(rating < 1) return;
    const response =  await Network.sendRequest('post', '/rating', {"id":leftRating.id, value:rating, "comment":ratingComment});
    if(response.data?.error)
       console.log(response.data?.error);
      else
        setleftRating({});
  }


  const setApiDataInCookie = (key, data) => {
    const jsonData = JSON.stringify(data);
    console.log(jsonData);
    Cookies.set(key, jsonData, { expires: 10 / 1440 });
  };

  const getApiDataFromCookie = (key) => {
    const jsonData = Cookies.get(key);
    return jsonData ? JSON.parse(jsonData) : null;
  };

  return (
     <div ref={referencia}>
        <AlertProvider>
      <BrowserRouter>
    
      <Alert />
        <Drawer open={openDraw} userdetail={base_user_info} SetDraw={SetDraw} handleClickOpen={handleClickOpen}/>
        <Header porcent={percentage.toPrecision(1)} SetDraw={SetDraw} handleClickOpen={handleClickOpen} blibkNumber={blibkNumber}/>
        
        <Dialog
          open={open}
          fullScreen={fullScreen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          className=''>
             <Login setopenSingup={setopenSingup} onClosePopup={handleClose} />
        </Dialog>

        <PopupRating  handleComment={handleComment}  submitRating={submitRating} leftRating={leftRating} rating={rating} handleRating={handleRating} expandRating={expandRating} fullScreen={fullScreen}/>

        <Dialog
          open={openSingup}
          onClose={handleCloseSingUp}
          maxWidth={"lg"}
          fullScreen={fullScreen}>
            <SignUp setopenSingup={setopenSingup} fullScreen={fullScreen}/>
        </Dialog>

        <Routes>
            <Route path="/" element={<Main instructorsList={instructorList} rantList={rantList??[]} setOpen={setOpen} open={open} handleClickOpen={handleClickOpen} userOnline={userOnline} userCount={userCount} subjectsList={subjects}/>} exact></Route>
            {/* <Route path="/tutors" element={<InstructorList instructors={instructorList} skills={subjects}/>} porcent={percentage.toPrecision(1)}></Route> */}
            <Route path="/tutors/profile/:id/list" element={<InstructorList instructors={instructorList} skills={subjects}/>} porcent={percentage.toPrecision(1)}></Route>
            <Route path="/for-tutors" element={<BecameInstructor instructors={instructorList} skills={subjects}/>}></Route>
            <Route path="/dashboard/:page" element={<Dashboard instructorsList={instructorList}/>}></Route>
            <Route path="/tutors/profile/:id" element={<InstructorSingle instructors={instructorList} setOpen={setOpen} />}></Route>
            <Route path="/dashboard/schedules/:day" element={<Dashboard instructorsList={instructorList} SetblibkNumber={SetblibkNumber}/>}></Route>
            <Route path="/dashboard/:page/:id" element={<Dashboard instructorsList={instructorList} SetblibkNumber={SetblibkNumber}/>}></Route>
            <Route path="/dashboard/:page/:id/:acepted" element={<Dashboard instructorsList={instructorList} SetblibkNumber={SetblibkNumber}/>}></Route>
            <Route path="/singup" element={<BecameInstructor instructors={instructorList} skills={subjects}/>} exact></Route>
            <Route path="/auth/forgot-password" element={<BecameInstructor instructors={instructorList} skills={subjects}/>} exact></Route>
            <Route path="/login" element={<BecameInstructor instructors={instructorList} skills={subjects}/>} exact></Route>
            
            <Route path="/study-help/:page" element={<StudyTips/>}></Route>
            <Route path="/become-instructor" element={<ApplyInstructor  skills={subjects}/>} exact></Route>
            <Route path="/dashboard/profile" element={<AccountManagment/>} exact></Route>
        </Routes>

        {showFooter  && <Footer />}
      </BrowserRouter>
      </AlertProvider>
  </div>
  );
}


