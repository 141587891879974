import React, { useState } from 'react';
import pec1 from '../images/Pic1.png';
import pec2 from '../images/Pic2.png';
import pec3 from '../images/Pic3.png';
import pec4 from '../images/Pic4.png';
import pec5 from '../images/Pic5.png';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const tips = [
  {
    title: 'O cérebro humano só aprende se estiver em “alerta e focado”.',
    content: (
      <div className='text-white'>
        <img src={pec1} alt="Pessoa com dúvida" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <Typography variant="body1" component="p">
          É facto que depois da infância para aprendermos precisamos de nos manter “ativos”, recorrentes…caso contrário não teremos sucesso.
          Existem várias técnicas ou métodos de estudo, e com isso convém irmos experimentando algumas, até nos identificarmos com uma que seja eficaz de acordo ao pretendido.
        </Typography>
        <Typography variant="body1" component="p">
          Não há uma técnica de estudo melhor que a outra…pois todos temos o nosso estilo/perfil de aprendizagem.
          Tenha em atenção que uma técnica pode ser eficaz para aprendermos sobre um certo tema, mas ineficaz para outros temas.
        </Typography>
        <Typography variant="body1" component="p">
          O truque está em nos conhecermos…” e bem”!
        </Typography>
      </div>
    ),
    backgroundColor: '#AB47BC',
  },
  {
    title: 'Aprendes melhor…',
    content: (
      <ul className="list-group list-group-borderless  ">
        {[
          '…sozinho ou em grupo?',
          '…com música ou TV ligada (luzes da tela)?',
          '…em casa ou num espaço público?',
          '…no silêncio ou no ruído?',
          '…em auto aprendizado ou com auxílio de outrem?',
        ].map((text, index) => (
          <li key={index} className="list-group-item h6 fw-normal d-flex text-white">
            <i className="bi bi-patch-check-fill text-success me-2" />
            {text}
          </li>
        ))}
      </ul>
    ),
    backgroundColor: '#E1BEE7',
  },
  {
    title: 'Método Cornell',
    content: (
      <div className='d-flex gap-3 flex-column flex-md-row '>
        <img src={pec2} alt="Método Cornell" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <div>
          <Typography variant="body1" component="p" className='text-white'>
            Embora seja antigo, continua válido e produtivo. É uma boa forma para resumirmos e aprendermos os temas de forma organizada…
          </Typography>
          <Typography variant="body1" component="p"  className='text-white'>
            É bem simples de ser aplicado e para tal só precisas de uma folha, a esferográfica e “brilhar”.
          </Typography>
        </div>
      </div>
    ),
    backgroundColor: '#CE93D8',
  },
  {
    title: 'Sistema de Leitner',
    content: (
      <div className='d-flex gap-3 flex-column flex-md-row text-white'>
        <img src={pec3} alt="Sistema de Leitner" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <div>
            <Typography variant="body1" component="p">
              Essa técnica tem a grande vantagem de manter o teu cérebro em “recordação ativa” no conteúdo em estudo, devido a sua recorrência e dinâmica.
            </Typography>
            <Typography variant="body1" component="p">
              Basicamente, é um método de aprendizagem que usa cartões rápidos (flashcards), caixinhas para os cartões e um agendamento para as repetições diárias, semanais e ou mensais.
            </Typography>
        </div>
      </div>
    ),
    backgroundColor: '#BA68C8',
  },
  {
    title: 'Plano de estudo',
    content: (
      <>
      <div className='d-flex gap-3 flex-column flex-md-row text-white mb-3'>
        <img src={pec4} alt="Plano de estudo" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <Typography variant="body1" component="p">
          Precisamos de enfatizar que é importante criarmos uma rotina diária de estudo, organizada. Para tal, o uso de um plano / calendário de estudo com as disciplinas distribuídas diariamente de acordo a dificuldade de cada uma, é recomendado.
        </Typography>
      </div>
     
       <img src={pec5} alt="Calendário de estudo" style={{ maxWidth: '100%', marginBottom: '15px' }} />
      </>
    ),
    backgroundColor: '#AB47BC',
  },
];

function GeneralTips() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="container" display="flex" flexDirection="column" alignItems="center">
   
      <Card
        style={{
          width: '100%',
          backgroundColor: tips[activeStep].backgroundColor,
          margin: '20px',
          padding: '20px',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom className='text-white'>
            {tips[activeStep].title}
          </Typography>
          {tips[activeStep].content}
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          className='rounded-5'
          disabled={activeStep === 0}
          style={{visibility:activeStep !== 0?"visible":"hidden"}}
          startIcon={<ArrowBack />}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className='rounded-5'
          onClick={handleNext}
          disableElevation
          style={{visibility:activeStep === tips.length - 1?"hidden":"visible "}}
          disabled={activeStep === tips.length - 1}
          endIcon={<ArrowForward />}
        >
          Próxima Dica
        </Button>
      </Box>
    </Box>
  );
}

export default GeneralTips;
