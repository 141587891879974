import React,{useState,useEffect} from 'react';
import logo from '../images/logo.png';
import logoWhite from '../images/Eureca_Logo_Branco.png';
import {Link} from "react-router-dom";
import {pathArray,getDecodedToken} from '../util/utilities';
import MenuIcon from '@mui/icons-material/Menu';
import {Badge,useMediaQuery,useTheme,Button,Container} from '@mui/material';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import { useParams } from 'react-router-dom';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useLocation } from 'react-router-dom';
const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function Header(props) {

  const base_user_info = getDecodedToken();
  const [inviteCount,setinviteCount] = useState(0);

  const headerToggle = (props.porcent > 0.4);
  const {id} = useParams();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [showFixedButton, setShowFixedButton] = useState(false);

  
  useEffect(()=>{
    const db = getDatabase();
    onValue(ref(db, 'AlertCount/' + base_user_info?.id??"0"), (snapshot) => {
      const dataResponse = snapshot.val();
      setinviteCount(dataResponse?.count);
    });

  },[]);

      // Effect to add window scroll event listener
      useEffect(() => {
        const handleScroll = () => {
          // Get the current scroll position.
          const currentScrollY = window.scrollY;
    
          // Determine if the fixed button should be shown based on the scroll position.
          // You can adjust the `scrollPositionToShowButton` to the position you want.
          const scrollPositionToShowButton = 330; // Example value.
    
          if (currentScrollY > scrollPositionToShowButton) {
            setShowFixedButton(true);
          } else {
            setShowFixedButton(false);
          }
        };
    
        // Add the event listener when the component mounts.
        window.addEventListener('scroll', handleScroll);
    
        // Remove the event listener when the component unmounts.
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);



//"#4b1172"
    return (
      <header style={{background:Array.isArray(configs?.menu)? configs?.menu[0]?.bg:"#4b1172"}} className={showFixedButton&&"sticky-top"}>
        {/* Logo Nav START */}
        <Container className="navbar navbar-expand p-0" >
          <div className="container h-60px d-flex  p-0 w-100">
            {/* Logo START */}
              <Link  to="/" className='pt-3 pb-3'>
              <img
                  className="w-100px"
                  src={logoWhite}
                  alt="logo"
                />
              </Link> 


              <div className="flex flex-row align-items-center">
                <div className="m-1 p-0">
                  {base_user_info.role == "NONE" || base_user_info.role == undefined?
                  <div className='d-flex gap-4 align-items-center'>
                    <Link className="nav-link text-white d-none d-xl-block" to='/for-tutors'>Dar aulas particulares</Link>
                    <Link className="nav-link text-white d-none d-xl-block" to='/study-help/general'>Dicas de estudo</Link>
                    <Link className="nav-link text-white" to='/study-help/general'><HelpOutlineOutlinedIcon/></Link>
                    <Button className='btn text-white d-lg-none' onClick={()=>{props.SetDraw(true);}}><MenuIcon/></Button>
                    {!isMobile &&  <Button onClick={props.handleClickOpen} style={{background:"#fff", borderRadius:"30px", fontSize:"13px", textTransform:"capitalize", width:"150px", color:"#4b1172"}}>{Array.isArray(configs?.menu)  && configs?.login[0].value}</Button>}
                  </div>:
                    <div className='d-flex gap-3 align-items-center p-2'>
                       <Link className="nav-link text-white d-none d-xl-block" to='/for-tutors'>Dar aulas particulares</Link>
                      <Link className="nav-link text-white d-none d-xl-block" to='/study-help/general'>Dicas de estudo</Link>
                      <Link className="nav-link text-white" to='/study-help/general'><HelpOutlineOutlinedIcon/></Link>

                       
                            {!location.pathname.includes("profile") && !location.pathname.includes("messages")  &&
                                <Link className='flex align-items-center justify-content-center' to={base_user_info.role == "TUTOR"?'/dashboard/messages':''}>
                                  
                                
                                    <button className='btn' style={{fontSize:"25px", position:"relative",top:"4px"}}>
                                        <Badge badgeContent={inviteCount} color="error">
                                        <NotificationsRoundedIcon className='text-white h1'/>
                                        </Badge>
                                    </button>
                                 
                                   
                               </Link>
                            }
                         
                          
                         
                          <Link className='flex align-items-center justify-content-center' to={(!isMobile || !isTablet)?'/dashboard/profile':''} onClick={()=>{if(isMobile || isTablet)props.SetDraw(true);}}>
                        <button className="btn avatar p-0 rounded-5 overflow-hidden" style={{height:"40px", width:"40px"}} >
                          <img
                            src={base_user_info.imageProfile??"https://ui-avatars.com/api/?name="+base_user_info.name}
                          />
                        </button>
                        </Link>
                        {/* <button className=' text-dark p-2 d-lg-none btn' style={{fontSize:"20px", position:"relative",top:"2px"}} onClick={()=>{props.SetDraw(true);}}><MenuIcon/></button> */}
                    </div>
                     }
                </div>
              </div> 
          </div>
        </Container>
        {/* Logo Nav END */}
      </header>
    );
}

export default Header;