import React, { useState } from 'react';
import BgSec from './BgSec';
import Services from './Services';
import Instructors from './Instructors';
import svgFile from '../images/element/P002.3.1.png';
import { Chip, Grid, Typography, useMediaQuery, useTheme, Button, Avatar, Rating, Box, Container } from '@mui/material';
import StarPurple500RoundedIcon from '@mui/icons-material/StarPurple500Rounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link } from "react-router-dom";
import { getDecodedToken } from '../util/utilities';
import TruncatedText from "../modules/TruncatedText";

const configs = JSON.parse(window.localStorage.getItem("configs" + window.localStorage.getItem("version")));

function isEven(number) {
  return number % 2 === 0;
}

function splitArrayIntoGroups(array, groupSize) {
  const result = [];
  for (let i = 0; i < 60; i += groupSize) {
    result.push(array.slice(i, i + groupSize));
  }
  return result;
}

function Main(props) {
  const base_user_info = getDecodedToken();
  const CONFIG_SEC3 = configs?.section3;
  const CONFIG_SEC2 = configs?.section2;
  const CONFIG_BANNER = configs?.banner;

  const { userOnline, userCount, instructorsList, subjectsList, rantList } = props;

  const groupedArrays = splitArrayIntoGroups(subjectsList, 20);

  const [stuckindex, setstuck] = useState(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (<>
    <BgSec userOnline={userOnline} userCount={userCount} />
    <Container>
      <Box component="main" sx={{ bgcolor: 'background.paper' }}>
       
        {/* <Services /> */}
        <Instructors instructors={instructorsList} />

        <Box component="section" sx={{ bgcolor: 'common.white', py: 4 }}>
          <Box sx={{ mb: 4, textAlign: 'left' }}>
            <Typography variant={isMobile || isTablet?"h4":"h3"} component={isMobile || isTablet?"h4":"h3"} fontWeight="bold">
              {Array.isArray(CONFIG_SEC2) && CONFIG_SEC2[0].value}
            </Typography>
            <Typography variant="body1">
              {Array.isArray(CONFIG_SEC2) && CONFIG_SEC2[1].value}
            </Typography>
          </Box>
          <Grid container gap={3} style={{ overflowX:isMobile || isTablet?'scroll':'hidden', padding: 16 }}  justifyContent="flex-start" wrap="nowrap">
            {groupedArrays.map((subjectList, index) => (
              <Grid item key={index} sx={{ p: 4, borderColor: 'divider', bgcolor:"divider", borderRadius: 2 }}  xs={9} sm={4} md={3.8}>
                {subjectList.map((subject, count) => (
                  <TruncatedText key={count} text={subject.title} limit={ isMobile || isTablet?20:35} />
                ))}
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box component="section" sx={{ bgcolor: 'background.default', py: 5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: isMobile || isTablet ? 'column' : 'row',
              mb: 4
            }}
          >
            <Box sx={{ width: '100%', mb: isMobile || isTablet ? 4 : 0 }}>
              <Rating value={5} readOnly />
              <Typography variant={isMobile || isTablet?"h4":"h3"} component={isMobile || isTablet?"h4":"h3"}fontWeight="bold">
                {Array.isArray(CONFIG_SEC3) && CONFIG_SEC3[0]?.value}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {Array.isArray(CONFIG_SEC3) && CONFIG_SEC3[2].value}
              </Typography>
              {!isMobile && !isTablet && (
                <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                  <Button
                    disabled={stuckindex > (groupedArrays.length - 1)}
                    onClick={() => setstuck(stuckindex + 1)}
                    variant="outlined"
                    color="primary"
                  >
                    <ArrowBackRoundedIcon />
                  </Button>
                  <Button
                    disabled={stuckindex < 0}
                    onClick={() => stuckindex > 0 && setstuck(stuckindex - 1)}
                    variant="outlined"
                    color="primary"
                  >
                    <ArrowForwardRoundedIcon />
                  </Button>
                </Box>
              )}
            </Box>
            <Grid container spacing={2} sx={{ overflowX: isMobile || isTablet ? 'auto' : 'hidden', width: '100%' }}>
              {rantList?.map((user, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 4,
                    borderRadius: 3,
                    gap: 3,
                    position: !isMobile && !isTablet && stuckindex >= index ? 'absolute' : 'relative',
                    right: !isMobile && !isTablet && stuckindex >= index ? '23.5vw' : '0',
                    width: 350,
                    bgcolor: isEven(index) ? 'primary.dark' : 'success.light',
                    color: isEven(index) ? '#fff' : 'text.primary',
                    zIndex: !isMobile || !isTablet ? (stuckindex >= index ? 100 : 1) : 1
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar src={`https://ui-avatars.com/api/?name=${user.proffname}`} sx={{ width: 40, height: 40, mr: 2 }} />
                      <Box>
                        <Typography variant="body1" fontWeight="bold">
                          {user.proffname}
                        </Typography>
                        <Typography variant="body2">Prof de matemática</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" fontWeight="bold" sx={{ minHeight: '10em' }}>
                        {user.comment}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', bgcolor: 'common.white', borderRadius: 2, p: 2 }}>
                      <Typography variant="body1" sx={{ mr: 2 }}>
                        {user.userName}
                      </Typography>
                      <Rating value={user.userName} readOnly />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box component="section" sx={{ bgcolor: 'common.white', py: 4 }}>
          <Box sx={{ background: `url(${svgFile}) center/cover no-repeat`, borderRadius: 3, overflow: 'hidden' }}>
            <Box sx={{ position: 'relative', height: '40em' }}>
              {!isMobile && (
                <Grid container direction="column" sx={{ p: 5, bgcolor: CONFIG_BANNER?.[2].bg, borderRadius: 2, m: 3, width: 450,position: 'relative',top:"18.5em" }}>
                  <Typography variant="h1" color="common.white" sx={{ fontSize: '2rem' }}>
                    {CONFIG_BANNER?.[0].value}
                  </Typography>
                  <Typography variant="body1" color="common.white">
                    {CONFIG_BANNER?.[3].value}
                  </Typography>
                  <Link to={base_user_info.role !== "NONE" ? "/become-instructor" : "/signup"} style={{ textDecoration: 'none', marginTop: '1em', }}>
                    <Button fullWidth variant="contained" sx={{ color: 'common.black', fontWeight: 'bold', bgcolor: 'common.white' }}>
                      <StarPurple500RoundedIcon sx={{ mr: 1 }} />
                      {CONFIG_BANNER?.[4].value}
                    </Button>
                  </Link>
                </Grid>
              )}
              {isMobile && (
                <Grid container justifyContent="center" alignItems="center" sx={{ bgcolor: CONFIG_BANNER?.[2].bg,position: 'relative', top:"20em", p: 4 }}>
                  <Grid item xs={12} lg={6} sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography variant="h5" color="common.white">
                      {CONFIG_BANNER?.[0].value}
                    </Typography>
                    <Typography variant="h6" color="common.white">
                      {CONFIG_BANNER?.[3].value}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3} sx={{ textAlign: 'center' }}>
                    <Link to={base_user_info.role !== "NONE" ? "/become-instructor" : "/signup"} style={{ textDecoration: 'none' }}>
                      <Button variant="contained" sx={{ color: 'common.black', fontWeight: 'bold', bgcolor: 'common.white' }}>
                        <StarPurple500RoundedIcon sx={{ mr: 1 }} />
                        {CONFIG_BANNER?.[4].value}</Button>
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container></>
  );
}

export default Main;
