import { useEffect,useRef,useState,useCallback } from 'react';
import {getDecodedToken} from '../util/utilities';
import {Link,useParams} from "react-router-dom";
import Network from '../network/networkRequests';
import Payment from '../modules/payment';
import {useMediaQuery,useTheme,Button,Badge,IconButton,Grid,Chip,Typography,Box} from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import HashLoader from "react-spinners/HashLoader";
import bg from "../images/minbanner.png";
import StarPurple500RoundedIcon from '@mui/icons-material/StarPurple500Rounded';
import {useDropzone} from 'react-dropzone'
import SensorOccupiedRoundedIcon from '@mui/icons-material/SensorOccupiedRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useAlert } from '../modules/AlertContext';
import EditIcon from '@mui/icons-material/Edit';
import AnalysisBanner from './AnalysisBanner'
import PaymentPopup from "../views/PaymentPopup";

var CurrencyFormat = require('react-currency-format');
const removeEmptyKeys = (obj) => {
    return Object.keys(obj)
        .filter(key => obj[key] !== undefined && obj[key] !== null && obj[key] !== '')
        .reduce((newObj, key) => {
            newObj[key] = obj[key];
            return newObj;
        }, {});
};
const configs =   JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));
const base_user_info = getDecodedToken()

export default function AccountManagement(props) {
    const {} = props;

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const handleOpenPayment = () => {
    setIsPaymentOpen(true);
  };

  const handleClosePayment = () => {
    setIsPaymentOpen(false);
  };
    const { showAlert } = useAlert();
    const [imageSrc, setImageSrc] = useState(null);
    const [imageEditor, setimageEditor] = useState(false);
    const [fileExtension, setFileExtension] = useState('');

    const [profile, setUserProfile] = useState({});
    const [currentprice,setcurrentprice] = useState(0);
    const [open, setOpen] = useState(false);

    const [loading, setloading] = useState(false);
    const [saved, setsaved] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const descriptionRef = useRef();
    const presentationRef = useRef();

    const grauRef = useRef();
    const instituitionRef = useRef();
    const cuurentJobRef = useRef();

    const phoneRef = useRef();
    const generoRef = useRef();
    
    useEffect(() => {
        getUserprofile();
      }, []);

    useEffect(() => {
        setcurrentprice(parseFloat(base_user_info?.price??0));
      }, [base_user_info]);

      useEffect(() => {
        if(currentprice <= 0) return;
        UpdateUserprice();
      }, [currentprice]);

      const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
          const extension = file.name.split('.').pop();
          setFileExtension(extension.toLowerCase());
          reader.onload = () => {
            const arrayBuffer = reader.result;
            setImageSrc(arrayBuffer);
          };
          reader.readAsDataURL(file);
        })
        
      }, [])
      const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/png, image/webp'})

    const getUserprofile = async () => {
        try{
          setloading(true);
          const profileTpm = await Network.sendRequest('get', '/user/profile', {});
          setUserProfile(profileTpm.data);

          presentationRef.current.value =  profileTpm.data.shortDescription;
          descriptionRef.current.value =  profileTpm.data.description;
          instituitionRef.current.value =  profileTpm.data.institution;
          cuurentJobRef.current.value =  profileTpm.data.jobTitle;

          grauRef.current.value =  profileTpm.data.grade;
          setcurrentprice(parseFloat(profileTpm.data.price??0));
          setloading(false);
        }catch(e){
          setloading(false);
        }
     };

     const saveProfile = async () => {
      try{
        setloading(true);
        const data = {"photoUrl": imageSrc,"extention":fileExtension}

        const response = await Network.sendRequest('post', '/user/profile/photo', data);
        localStorage.setItem("token",response.data.token);
        localStorage.setItem("refreshToken",response.data.refreshToken);
        setloading(false);
        showAlert("Foto e perfil atualizada", 'success');
      }catch(e){
        showAlert(e, 'error');
      }
   };


    const UpdateUserprice = async () => {
        await Network.setPriceUpdate({price: currentprice});
     };

     const UpdateUserData = async () => {
        setloading(true);
        try{
          const body = {
            description: descriptionRef.current?.value,
            shortDescription: presentationRef.current?.value,
            institution: instituitionRef.current?.value,
            genero:phoneRef.current?.value,
            tel:phoneRef.current?.value,
            job_title: cuurentJobRef.current?.value,
            grade: grauRef.current?.value};

        const response = await Network.setPriceUpdate(removeEmptyKeys(body));
        
        if(response.statusText === "OK")setsaved(true);

        }catch(e){
          console.log(e);
        }
      
        setloading(false);
        
        setTimeout(()=>{
            setsaved(false);
        },1000);
      
     };


     const anonimuosLogin = async ()=>{

      var data = JSON.stringify({
        "username": "anonimius@any.com",
        "password": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9"
      });
      
      const response =  await Network.sendRequest('post', '/login', data);
        if(response.data?.error){
          console.log("ERROR",response.data);
        }else{
          localStorage.setItem("token",response.data.token);
          localStorage.setItem("refreshToken",response.data.refreshToken);
          window.location.href = '/';
        }
    };


    const importantBadge = <strong className='text-danger'>*</strong>;

    return <div className='container'>

    {base_user_info.role !== "TUTOR"?
        <div className='d-flex mt-3 border rounded-5 mb-2' style={{background:configs?.banner[2].bg}}>
            <div>
              <Typography variant="body3" className='d-flex flex-column p-4 gap-4'> 
                <Typography variant="body3" style={{textAlign:"justify"}} className='h5 text-white'>{configs.profile[8].value}</Typography> 
                <p className='text-white' style={{textAlign:"justify"}}>{configs.profile[9].value}</p>
                <Link to="/become-instructor" style={{textDecoration:"none"}}>
                    <Button href="#" style={{color:"#000", fontWeight:"bold", width:!isMobile?"20em":"",height:"50px"}} className="d-flex btn bg-white p-2 rounded-5 "><StarPurple500RoundedIcon/> <div>  {configs.profile[10].value}</div></Button>
                </Link>
              </Typography>
            </div>
            {!isMobile && <div>
              <img src={bg} style={{borderTopRightRadius:"25px",borderBottomRightRadius:"25px", height:"100%", minWidth:"350px"}}/>
            </div> }
          
        </div>:<AnalysisBanner base_user_info={base_user_info} handleOpenPayment={handleOpenPayment}/>
    }

  
    <main className="card card-body p-0 mt-3 border shadow-0  bg-white">
        <div className="d-flex card-header justify-content-between align-items-center  bg-transparent border-bottom">
        
          <div className='d-flex align-items-center gap-3'>
          <img
                      id="uploadfile-1-preview"
                      style={{height:"50px", width:"50px"}}
                      className="avatar-img rounded-circle border border-white border-3 shadow"
                      src={profile.profileImage??"https://ui-avatars.com/api/?name=" + base_user_info.name}
                      alt="" />
                       <div>
                       <h4 className="mb-0" style={{fontSize:"14px"}}>{base_user_info.name}</h4>
                       <Button style={{textTransform:"none"}} onClick={()=>{setimageEditor(true)}} className="d-flex gap-2 mainTexColor align-items-center justify-content-center p-0">Editar<EditIcon style={{fontSize:"13px"}}/></Button>
                       </div>
          </div>
         <div className='d-flex gap-2'>
         <Link to={"/dashboard/messages"}>
          
                <Button 
                style={{
                  height: '50px',
                  fontWeight: 'bold',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                    color: '#fff',
                  },
                }}
                className="d-flex gap-2 mainColor p-2 align-items-center justify-content-center rounded-5">  <Badge badgeContent={1} color="error">
                 {!isMobile && <span style={{fontSize:"14px", paddingLeft:"10px", textTransform:"none"}}>Notificações</span>}<NotificationsRoundedIcon/></Badge>
                </Button>
            
         </Link>
         {!isMobile && <Button onClick={anonimuosLogin} className="d-flex gap-2 mainTextColor p-2 align-items-center justify-content-center rounded-5">Sair</Button>}
         </div>
        </div>
        {/* Card body START */}
        <div className="card-body">
          {/* Form */}
          <form className="row ">
            {/* base_user_info picture */}
            <div className="justify-content-between mb-4 d-flex gap-2" style={{flexDirection:isMobile?"column":"row"}}>
            {imageEditor &&               <div className="d-flex align-items-start  align-items-center flex-column gap-2 w-100 border rounded">
                {/* <label className="form-label">{configs.profile[0].value}</label> */}
                <label
                  className="position-relative me-0"
                  htmlFor="uploadfile-1"
                  title="Replace this pic"
                >
                  {(!imageEditor && imageSrc == null) &&
                      <img
                      id="uploadfile-1-preview"
                      style={{height:"150px", width:"150px"}}
                      className="avatar-img rounded-circle border border-white border-3 shadow"
                      src={profile.profileImage??"https://ui-avatars.com/api/?name=" + base_user_info.name}
                      alt="" />
                  }
                  {imageEditor &&
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {!imageSrc &&
                            <div className='d-flex justify-content-center align-items-center gap-3 flex-column'>
                              <div><SensorOccupiedRoundedIcon style={{fontSize:"2.2em"}}/></div>
                              <p>Carregue ou large um arquivo aqui</p>
                            </div>}

                          {imageSrc &&
                             <img
                             id="uploadfile-1-preview"
                             style={{height:"150px", width:"150px"}}
                             className="avatar-img rounded-circle border border-white border-3 shadow"
                             src={imageSrc}
                             alt="" />
                          }
                        </div>
                      </section>
                  }
                  {/* <span className="avatar avatar-xl">
                   
                  </span> */}
                  {/* Remove btn */}
                  {/* <button type="button" className="uploadremove">
                    <i className="bi bi-x text-white" />
                  </button> */}
                </label>
              
                  <div className='d-flex justify-content-between border rounded w-100 p-2'>
                      <Button className="mainTextColor"  onClick={()=>{setImageSrc(null); setimageEditor(false)}} disableElevation>Cancelar</Button>
                      <Button className="mainColor"  disableElevation onClick={saveProfile}>  {loading ? <HashLoader size={20} loading />:"Salvar"}</Button>
                  </div>
                
              </div>
              }

              {Number(base_user_info.featured) > 1 &&
              <div className="d-flex align-items-center justify-content-center gap-1 w-100">

                <Button type='button' className=" mb-0 p-4" onClick={() => { setcurrentprice(currentprice - 100); } }>
                <div style={{fontSize:"2em"}}>-</div>
                </Button>
                <div className='d-flex flex-column gap-2 align-items-center' style={{ width: "60%" }}>
                  <CurrencyFormat value={currentprice} displayType={'text'} format="#.###.00 Kzs" className='h3 border-0 w-100 text-center mt-3' />
                  <label className='p-2'>{configs.profile[1].value}</label>
                </div>
  
                <Button type='button' className=" mb-0 p-4" onClick={() => { setcurrentprice(currentprice + 100); } }>
                  <div style={{fontSize:"2em"}}>+</div>
                </Button>
  
              </div>
              }
            </div>
  
  
            {base_user_info.role == "TUTOR" ?
              <>
  
                <div className="col-12">
                    <input
                  className="form-control mb-2"
                  style={{ border: 'none', padding: '10px' }}
                  type="text"
                  disabled
                  defaultValue={base_user_info?.email} />
                </div>
                <div className="col-12 mt-3">
                  <label className="form-text  pt-2 pb-2">{configs.profile[2].value} {importantBadge} </label>
                  <textarea
                    ref={presentationRef}
                    className="form-control"
                    style={{ border: '1px solid #333', fontSize:"13px", background: 'none', padding: '5px' }}
                    rows={5}
                    placeholder='Max 250 letras'
                    defaultValue={base_user_info?.description}></textarea>
                  <div className="form-text pt-2 pb-2">{configs.profile[3].value}{importantBadge}</div>
                  <textarea
                    ref={descriptionRef}
                    className="form-control"
                    style={{ border: '1px solid #333', fontSize:"13px", background: 'none', padding: '5px' }}
                    rows={5}
                    placeholder='Max 1350 letras'
                    defaultValue={base_user_info?.description} ></textarea>
                </div>
                 {/* Education */}
            {
              Number(base_user_info.featured) > 1 &&  <div className="col-12 ">
              <label className="form-label pt-2 pb-2 w-100">{configs.profile[4].value}</label>
              {/* <label className='text-center w-100'>Sem esperiencia adicionada</label> */}

              <input
                  ref={cuurentJobRef}
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="text"
                  placeholder={configs.profile[5].value}/>

              <input
                  ref={instituitionRef}
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="text"
                  placeholder={configs.profile[6].value}/>
              
              <input
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="text"
                  ref={grauRef}
                  placeholder={configs.profile[7].value}/>


              <Button className="mainTextColor" fullWidth={isMobile}>
                <i className="bi bi-plus me-1" />
                Adicionar
              </Button>
            </div>
            }
           
              </> :  
            <div className="col-12 ">
              <label className="form-label pt-2 pb-2 w-100">Meus dados de conta</label>
              <input
                  ref={phoneRef}
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="tel"
                  placeholder={"+244: Telemóvel"}/>

              <input
                  ref={generoRef}
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="text"
                  placeholder={"Género Exemplo(Masculino, Femenino,Outro)"}/>
              
              <input
                  className="form-control mb-2"
                  style={{ border: '1px solid #333', padding: '10px' }}
                  type="text"
                  ref={grauRef}
                  placeholder={configs.profile[7].value}/>
            </div>}
  
           
            {/* Save button */}
            <div className="d-sm-flex justify-content-end mt-3">
              <Button 
              style={{
                height: '50px',
                fontWeight: 'bold',
                transition: 'background 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  color: '#fff',
                },
              }}
              disabled={loading || saved} type="button" className={(saved?"bg-success text-white p-2":"mainColor p-2")} onClick={UpdateUserData} fullWidth={isMobile}>
               <span className='pl-2 pr-2' >
               {loading ? <HashLoader color="#ffffff" size={20} loading />:saved?"Salvo!":"Salvar Alteração"}
               </span>
              </Button>
            </div>
          </form>
        </div>
  
      {open ? <Payment handleClose={()=>setOpen(false)} /> : null}
    </main>
    <PaymentPopup open={isPaymentOpen} onClose={handleClosePayment} />
    </div>;
  }
  