import axios from 'axios';
const MAIN_URL = "https://api.eureca.ao/v0.1";

const MAIN_HEADER = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + localStorage.getItem('token'),
};

const Network = {
  sendRequest: (method, url, data, headers) => {
    let config = {
      method: method,
      maxBodyLength: Infinity,
      url: MAIN_URL + url,
      headers: headers || MAIN_HEADER,
    };

    if (method.toLowerCase() === 'post') {
      config.data = data;
    } else if (method.toLowerCase() === 'get') {
      config.params = data;
    }

    return axios.request(config);
  },
  getUsersData: (params) => {
    return Network.sendRequest('get', '/users', params);
  },
  getUsersPlans: (params) => {
    return Network.sendRequest('get', '/plans', params);
  },

  getUsersInvites: (params) => {
    return Network.sendRequest('get', '/message/getenvites', params);
  },
  
  getallSubjects: (params) => {
    return Network.sendRequest('post', '/subjects/profile/getsubjects', params);
  },
  getRequestApply: (userid) => {
    return Network.sendRequest('post', '/admin/applyviewer', {userid: userid});
  },
  submitRequestequestApply: (data) => {
    return Network.sendRequest('post', '/request/sendinvite', data);
  },
  setPriceUpdate: (body) => {
    return Network.sendRequest('post', '/updateuserdata', body);
  },

};

export default Network;