import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Divider, makeStyles } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow:"none",
    width: '10vw',
    textAlign: 'center',
    border: '2px solid #f2f2f2',
    borderRadius:"12px",
    padding:"5px"
  },
  header: {
    textAlign: 'center',
    spacing: 5,
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const PaymentTypes = React.memo(function PricingCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
       <div>{props.detail.name}</div>
       <Divider variant="middle" />
      <div>
        <img src={props.detail.Icon} alt={"Icon"} srcset="" />
      </div>
    </Card>
  );
});

export default PaymentTypes