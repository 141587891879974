import React from 'react';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { useAlert } from './AlertContext';

const Alert = () => {
  const { alert, hideAlert } = useAlert();

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={6000}
      onClose={hideAlert}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
    >
      <MuiAlert onClose={hideAlert} severity={alert.severity} sx={{ width: '100%' }}>
        {alert.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
