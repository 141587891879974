import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import HashLoader from "react-spinners/HashLoader";
import {headers,getDecodedToken} from '../util/utilities';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import axios from 'axios';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import { IconButton,Badge,Dialog,DialogTitle,DialogContent,DialogActions,useTheme,useMediaQuery  } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import Recadule from "./Recadule";
import Network from '../network/networkRequests';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
const base_user_info = getDecodedToken();

const timeSet = (data) => {
    if (data === undefined) return 0;
    const value = (-deffdate(data));
    const rtfolder = new Intl.RelativeTimeFormat("pt", { numeric: "auto" });
    const current = rtfolder.format(parseInt(value), "day");
    return current;
}

const deffdate = (startdate) => {
    const today = new Date();
    const diffInMs = new Date(today) - new Date(startdate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays;
}

const UserItems = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   

    const { item} = props;

    
    const [notificationCount,setnotificationCount] = useState(0);
    const firebaseSavePath = 'AlertCount/' + item.MsgFrom;

    React.useEffect(()=>{

        onValue(ref(getDatabase(), firebaseSavePath), (snapshot) => {
        const dataResponse = snapshot.val();
        setnotificationCount(dataResponse.count??0);
        });
    },[]);

    const [open, setOpen] = React.useState(false);
  

    const [loading, setLoading] = useState(false);

    const sendApprove = async() => {
        setLoading(true);

        const response =  await Network.sendRequest('post', '/aceptInvite', JSON.stringify(item));

        if(response.data.success){
            setLoading(false);
            window.location.href = '/dashboard/invits/acepted';
            set(ref(getDatabase(), firebaseSavePath),{"count":notificationCount+1});
        }else{
            setLoading(false);
        }
    }

    const isMine =  item.MsgFrom !== base_user_info.id;

    const chatBtn = <IconButton>
        <Badge badgeContent={0} color="error">
            <MarkUnreadChatAltRoundedIcon />
        </Badge>
    </IconButton>;

    

    return (
        <div className='d-flex justify-content-between align-items-center border-top p-2' >
            <div onClick={()=>setOpen(true)} className='shadow-none w-100' style={{minWidth:"50px"}}>
                <AccordionSummary
                    className='bg-none border-0'>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className='d-flex align-items-center'>
                            <div
                                className="avatar mb-2 mb-md-0 rounded-circle"
                                style={{
                                    background: `url('${item?.user?.profileImage === "" ? "https://ui-avatars.com/api/?name=" + item.user?.nome : item.user?.profileImage}')`,
                                    backgroundSize: "cover"
                                }}
                            ></div>
                            <div className="mb-0 ms-2">
                                <h6 className="mb-0 small text-capitalize">
                                    {item?.user?.nome} {item?.user?.ultimoNome}
                                </h6>
                                <div className='d-flex flex-column'>
                                { item.accepted?
                                    <span className="badge bg-primary bg-opacity-10 text-primary">Ver mais</span>:
                                    item.faster === 1 ?
                                    <span className="badge bg-danger text-light"><i className="bi bi-lightning-charge-fill"></i>Urgente</span> :
                                    <span className="badge bg-primary bg-opacity-10 text-primary">{item.MsgFrom !== base_user_info.id?"Ver Mais":"Aguardar aprovacão"}</span>
                                }
                                </div>
                            </div>
                        </div>

                    </div>
                </AccordionSummary>
            </div>
            <div className='d-flex justify-content-center align-items-end gap-2 flex-column-reverse'>
                <label className='horLegend' style={{fontSize:"9px",whiteSpace:"nowrap"}}>{timeSet(item?.date)}</label>
                   <div>
                    {item.accepted?
                       <div className='d-flex gap-2'>
                          <Link to={'/dashboard/inbox/' + item?.user?.id}>  {chatBtn}</Link>
                          
                            <Button variant="contained" disableElevation className='mainColor d-flex gap-2 rounded'>{!isMobile && <strong style={{fontSize:"12px",whiteSpace:"nowrap"}}>Iniciar Aula</strong>} <VideocamRoundedIcon className='bg-ligh'/></Button>
                        </div>:
                    isMine? <Button variant="contained" className='rounded bg-primary bg-opacity-10 text-primary' style={{fontSize:"12px"}} disableElevation onClick={sendApprove}> {loading ? <HashLoader color="#ffffff" size={20} loading /> : <>Aceitar</>}</Button>:
                        
                        <div>
                               <Link to={'/dashboard/inbox/' + item?.user?.id}>
                                {chatBtn}
                            </Link>
                        </div>
                    }
               </div>
            </div>
            <Recadule open={open} handleClose={()=>setOpen(false)} isMobile={isMobile} item={item} isMine={isMine} sendApprove={sendApprove}  loading={loading}/>
        </div>
    );
}

export default UserItems;
