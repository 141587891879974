import React, { useState } from 'react';
import pec1 from '../images/Picture 1.png';
import pec2 from '../images/Picture 2.png';
import pec3 from '../images/Picture 3.png';
import pec4 from '../images/Picture 4.png';
import pec5 from '../images/Picture 5.png';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const tips = [
  {
    title: 'NUNCA INFERIORIZE…',
    content: (
      <>
        <Typography variant="body1" component="p">
          “Você já não aprende! Você é muito burro! Já falei que não é assim, mas que miúdo boelo!”  
          A missão de dar apoio emocional e cativar o interesse pelo estudo é sua (encarregado de educação).
        </Typography>
        <Typography variant="h6" component="h6" style={{ marginTop: '15px' }}>
          Nessa missão:
        </Typography>
        <ul className="list-group list-group-borderless">
          {[
            'Antes de tudo, é importante que identifique o estilo de aprendizagem da criança, para melhor encaminhamento;',
            'Evite gritar ou ser rude!',
            'Esteja disponível para ajudar nas lições…e nos casos em que não esteja, delegue, mas, ainda assim, faça um esforço de participar;',
            'Obs.: AJUDAR NÃO É FAZER NO LUGAR DESTE',
            'Cria, com ajuda da criança, uma rotina diária de estudo...fazendo-o perceber os motivos;',
            'Definam metas alcançáveis, de acordo aos temas a serem aprendidos;',
            'Incentive ao uso de ambiente apropriado, distante de distrações;',
            'Dê suporte emocional e saiba reconhecer a evolução da criança;',
          ].map((text, index) => (
            <li key={index} className="list-group-item h6 fw-normal d-flex">
              <i className="bi bi-patch-check-fill text-success me-2" />
              {text}
            </li>
          ))}
        </ul>
      </>
    ),
    backgroundColor: '#FFF3E0',
  },
  {
    title: 'Use o que tem, seja criativo…!',
    content: (
      <>
        <Typography variant="body1" component="p">
          Um exemplo prático, está nos desenhos animados que estes mais gostam (fique atento). 
          Ao título de exemplo usaremos o desenho <b>“Dragon Ball”</b>, de artes marciais, e o seu personagem principal <b> “GoKu”</b>:
          O GoKu, pela sua natureza, tem a <b>“necessidade”</b> de testar os seus limites e tornar-se cada vez mais forte! Então desafia os guerreiros considerados mais fortes em combate de artes marciais.
        </Typography>
        <img src={pec1} alt="GoKu" style={{ width: '100%', marginTop: '15px' }} />
      </>
    ),
    backgroundColor: '#FFECB3',
  },
  {
    title: '“Aqui aproveitamos e traduzimos à criança, de forma criativa”:',
    content: (
      <>
        <Typography variant="h6" component="h6" style={{ marginTop: '15px' }}>
          GoKu = Criança
        </Typography>
        <img src={pec2} alt="Desafio GoKu" style={{ width: '100%', marginTop: '15px' }} />
        <Typography variant="body1" component="p" style={{ marginTop: '15px' }}>
          <b>Desafio GoKu -{'>'}</b> derrotar um guerreiro = <b>Desafio Criança -{'>'} </b> ser aprovado numa prova
        </Typography>
      </>
    ),
    backgroundColor: '#FFE0B2',
  },
  {
    title: 'Sessões de Treinos Focadas',
    content: (
      <>
        <Typography variant="body1" component="p" style={{ marginTop: '15px' }}>
          Antes de qualquer desafio, o GoKu passa por <u>sessões de treinos muito focadas</u>, com objetivo de vencer “ou de pelo menos dar luta” ao seu oponente e assim mostrar o seu real valor.
        </Typography>
        <img src={pec3} alt="Sessões de Treinos" style={{ width: '100%', marginTop: '15px' }} />
      </>
    ),
    backgroundColor: '#FFD54F',
  },
  {
    title: 'Persistência e Dedicação',
    content: (
      <>
        <Typography variant="body1" component="p" style={{ marginTop: '15px' }}>
          Com o treino completo, o GoKu está sempre pronto para o combate e esforça-se para vencer! No entanto é importante frisar que o GoKu sabe perder, mas, nunca desiste de tentar até conseguir a vitória.
        </Typography>
        <img src={pec4} alt="Persistência de GoKu" style={{ width: '100%', marginTop: '15px' }} />
        <Typography variant="h5" component="h5" style={{ marginTop: '20px' }}>
          Temos aqui um exemplo de como criativamente podemos motivar a criança, e assim impactar na sua dedicação.
        </Typography>
        <br />
        <Typography variant="h5" component="h5" style={{ marginTop: '20px' }}>
        A IDEIA É CONHECER OS GOSTOS DA CRIANÇA E USAR ISSO COMO IMPULSO…
        </Typography>
        <img src={pec5} alt="Motivação da Criança" style={{ width: '100%', marginTop: '15px' }} />
      </>
    ),
    backgroundColor: '#FFCA28',
  },
];

function ChildrenTips() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="container" display="flex" flexDirection="column" alignItems="center" width="100%">
      
      <Card
        style={{
          width: '100%',
          backgroundColor: tips[activeStep].backgroundColor,
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            {tips[activeStep].title}
          </Typography>
          {tips[activeStep].content}
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2} mt={2}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          className='rounded-5'
          onClick={handleBack}
          style={{visibility:activeStep !== 0?"visible":"hidden"}}
          disabled={activeStep === 0}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className='rounded-5'
          onClick={handleNext}
          style={{visibility:activeStep !== tips.length - 1?"visible":"hidden"}}
          disabled={activeStep === tips.length - 1}
          endIcon={<ArrowForward />}>
           Próxima Dica
        </Button>
      </Box>
    </Box>
  );
}

export default ChildrenTips;
