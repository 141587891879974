import {DialogTitle,Dialog,useMediaQuery,useTheme,Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BrowserRouter, Routes, Route,Link } from "react-router-dom";
import Network from '../network/networkRequests';

export default function Drawer(props){
    const {open,SetDraw,userdetail,handleClickOpen} = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    var USER_ROLE = userdetail?.role??"NONE";

    const anonimuosLogin = async ()=>{

      var data = JSON.stringify({
        "username": "anonimius@any.com",
        "password": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9"
      });
      
      const response =  await Network.sendRequest('post', '/login', data);
        if(response.data?.error){
          console.log("ERROR",response.data);
        }else{
          localStorage.setItem("token",response.data.token);
          localStorage.setItem("refreshToken",response.data.refreshToken);
          window.location.href = '/';
        }
    };


    return !open?"":(
      <div className='drawer'>
      <div className='w-100  h-100 bg-white p-4'>
      <div class="drawer-header border-bottom p-3 d-flex justify-content-between" onClick={()=>{SetDraw(false);}}>
          {/* <h4 class="drawer-title" id="drawer-1-title">Drawer Title</h4> */}
          <Link className="p-3 rounded-5" to={USER_ROLE !== "NONE"? "/dashboard/profile":"/SingUp"}>{USER_ROLE !== "NONE"?"Minha conta":"Aplicar para explicador"}</Link>  
          <button className='btnColor rounded-4 border-0' style={{width:"50px", height:"50px"}} onClick={()=>SetDraw(false)}>
            <CloseIcon/>
          </button>
      </div>
      
      {USER_ROLE === "NONE" &&
      <div className="item-nav border-bottom d-flex gap-3 flex-column">
         <Button fullWidth className='btnColor h-50px rounded-5'  onClick={()=>{handleClickOpen();SetDraw(false);}}>Inciar sessão</Button>
         <Link to={"/SingUp"}><Button fullWidth className='bg-light h-50px rounded-5' onClick={()=>SetDraw(false)}>Registro</Button></Link>
      </div>}
      {USER_ROLE !== "NONE" &&
      <>
        <div className="item-nav border-bottom">
        <Link className="nav-link p-3" onClick={()=>{
           SetDraw(false);
        }} to='/dashboard/invits'><strong>Pedidos recebidos</strong></Link>
        </div>
      </>}

        <div className="item-nav border-bottom">
        <Link className="nav-link p-3" onClick={()=>{
           SetDraw(false);
        }} to='/for-tutors'><strong>Dar aulas particulares</strong></Link>
        </div>
        {/* <div className="item-nav border-bottom">
        <Link className="nav-link p-3" onClick={()=>{
           SetDraw(false);
        }} to='/get-tutors'> <strong>Dicas de Estudo</strong> </Link>
        </div> */}
        {/* <div className="item-nav border-bottom">
        <Link className="nav-link p-3" onClick={()=>{
           SetDraw(false);
        }} to='/for-tutors'> <strong>Para explicadores</strong> </Link>
        </div> */}
        <div className="item-nav border-bottom">
        <Link className="nav-link p-3" onClick={()=>{
           SetDraw(false);
        }} to='/study-help/general'><strong>Dicas de estudo</strong></Link>
        </div>

        {USER_ROLE !== "NONE" &&
        <div className="item-nav border-bottom">
        <Button className="nav-link p-3" style={{color:"#f00", textTransform:"none"}} fullWidth onClick={anonimuosLogin} ><strong style={{color:"$f00"}}>Terminar sessão</strong></Button>
        </div>}
      </div>
      
    </div>);
  }