import {DialogTitle,Dialog,useMediaQuery,useTheme,Button,DialogContent,DialogActions,Rating} from '@mui/material';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

export default function PopupRating(props) {
    const {leftRating, rating, handleRating, handleComment, expandRating, fullScreen, submitRating} = props;

    return <Dialog
      open={leftRating.id ? true : false}>
      <DialogTitle id="alert-dialog-title">{Array.isArray(configs?.rating) && configs?.rating[0].value} <strong>{leftRating?.name ?? ""}</strong>?</DialogTitle>
      <DialogContent>
        <div className='d-flex flex-column gap-2  justify-content-center align-items-center'>
          <Rating value={rating} size='large' icon={<StarRoundedIcon fontSize="large" />} onChange={handleRating} />
          {expandRating && <div className='d-flex flex-column gap-2 border w-100'>
            <label>{Array.isArray(configs?.rating) && configs?.rating[1].value}</label>
            <textarea onChange={handleComment} placeholder='Tem algo a dizer para o professor?' style={{ minHeight: "10em", padding: "10px" }}></textarea>
          </div>}
        </div>
      </DialogContent>
      <DialogActions className='text-center'>
        <div className='text-center w-100'>
          <Button disabled={rating < 1} autoFocus onClick={submitRating} fullWidth={fullScreen} className={rating > 0? 'mainColor':''} style={{ minWidth: "200px" }}>Enviar</Button>
        </div>
      </DialogActions>
    </Dialog>;
  }