import React, { useState,useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Grid,
  Box,
  Divider
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PayPalIcon from '@mui/icons-material/AccountBalanceWallet'; // Replace this with the actual PayPal icon below
import CustomPaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PayPalLogo from "../assets/paypal.webp";
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const PaymentPopup = ({ open, onClose }) => {
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  
  const [file, setFile] = useState(null);

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleFinishPayment = () => {
    // Handle the payment submission here
    console.log(`Método de pagamento: ${paymentMethod}`);
    onClose();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" sx={{ borderRadius: 5 }}>
      <DialogTitle sx={{ backgroundColor: '#f5f5f5', padding: '16px 24px', borderRadius: '5px 5px 0 0', display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        }}>
        <Typography variant="h6">Finalizar Pagamento</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px'}}>
        <Typography variant="body1" gutterBottom sx={{ paddingTop: '24px',paddingBottom: '24px'}}>
          Selecione o seu método de pagamento:
        </Typography>
        <RadioGroup value={paymentMethod} onChange={handlePaymentChange}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  borderRadius: '8px',
                  border: paymentMethod === 'creditCard' ? '2px solid #1976d2' : '1px solid #ccc',
                  backgroundColor: paymentMethod === 'creditCard' ? '#e3f2fd' : '#fafafa',
                  cursor: 'pointer',
                }}
                onClick={() => setPaymentMethod('creditCard')}
              >
                <CreditCardIcon style={{ marginRight: 8 }} />
                <Typography>Transferência(BAI)</Typography>
              </Box>
            </Grid>
            <Grid item xs={10} sm={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  borderRadius: '8px',
                  border: paymentMethod === 'paypal' ? '2px solid #1976d2' : '1px solid #ccc',
                  backgroundColor: paymentMethod === 'paypal' ? '#e3f2fd' : '#fafafa',
                  cursor: 'pointer',
                }}
                onClick={() => setPaymentMethod('paypal')}
              >
                <img src={PayPalLogo} alt="PayPal" style={{ height: 24, marginRight: 8 }} />
                <Typography>PayPal</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  borderRadius: '8px',
                  border: paymentMethod === 'custom' ? '2px solid #1976d2' : '1px solid #ccc',
                  backgroundColor: paymentMethod === 'custom' ? '#e3f2fd' : '#fafafa',
                  cursor: 'pointer',
                }}
                onClick={() => setPaymentMethod('custom')}
              >
                <CustomPaymentIcon style={{ marginRight: 8 }} />
                <Typography>Mtx Express</Typography>
              </Box>
            </Grid>
          </Grid>
        </RadioGroup>

        <Divider sx={{ margin: '24px 0' }} />

        {paymentMethod === 'creditCard' && (
          <Box>
            <div className='d-flex'>
            <TextField fullWidth label="IBAN: 0055.0000.0000.0000.00" margin="normal" variant="outlined" disabled />
            <IconButton>
              <div className='p-2'>
                  <ContentCopyIcon/>
              </div>
            </IconButton>
            </div>
            <Grid container spacing={2}>
              <Box
              className='m-3 mt-5'
              {...getRootProps()}
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                padding: '24px',
                textAlign: 'center',
                backgroundColor: '#f9f9f9',
                cursor: 'pointer',
                marginTop: '16px',
                position: 'relative',
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1" sx={{ color: '#888', marginTop: '8px' }}>
                Arraste e solte a prova de pagamento aqui, ou clique para selecionar um arquivo
              </Typography>
              <i
                className="bi bi-file-earmark-image"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontSize: '80px',
                  color: '#ddd',
                  transform: 'translate(-50%, -50%)',
                  pointerEvents: 'none',
                  opacity:0.2,
                  zIndex: 0,
                }}
              ></i>
              </Box>
              <Grid item xs={12} className='m-2 mt-0'>
              <Typography>Seu pedido será cuidadosamente processado dentro de um prazo de até 24 horas, caso o pagamento seja efetuado por meio de um banco diferente. Agradecemos sua compreensão e paciência durante esse período.</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* Additional fields can be added for PayPal and Custom Payment */}
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', backgroundColor: '#f5f5f5', borderRadius: '0 0 5px 5px' }}>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleFinishPayment}
          variant="contained"
          disableElevation
          className='mainColor'
          style={{
            height: '50px',
            fontWeight: 'bold',
            transition: 'background 0.3s ease',
            '&:hover': {
              // backgroundColor: theme.palette.primary.dark,
              color: '#fff',
            },
          }}
         // style={{borderRadius: '8px', padding:"10px"}}
          sx={{ marginLeft: '8px', backgroundColor: '#8a2be2', '&:hover': { backgroundColor: '#7a1cb5' } }}
        >
          Concluir Pagamento
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentPopup;
