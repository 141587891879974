import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import { IconButton,Badge,Dialog,DialogTitle,DialogContent,DialogActions,useTheme,useMediaQuery  } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';

export default function Recadule(props) {
    const {open, handleClose, isMobile, item, isMine, sendApprove, loading} = props;
    return <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobile}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div style={{ minWidth:isMobile? "20em":"30em" }}>
            {isMobile &&
                <DialogActions>
                        <IconButton onClick={handleClose} autoFocus>
                            <CloseRoundedIcon style={{ color: "#f00" }} />
                        </IconButton>
                </DialogActions>}
            <DialogTitle id="alert-dialog-title">Assistente de marcação</DialogTitle>

            <DialogContent>
                <Typography>
                    <div className="d-flex gap-2 flex-column mb-3">
                        <label className="form-control"><b>Data:</b> {item?.date}</label>
                        <label className="form-control"><b>Hora:</b> {item?.hour}</label>
                        <label className="form-control"><b>Disciplina:</b> {item?.subject}</label>
                    </div>

                    <div className='d-flex gap-2 justify-content-between'>
                        {isMobile &&
                            <Button onClick={handleClose} style={{ color: "#f00" }}>Cancelar Pedido</Button>}
                        <Link to={'/dashboard/inbox/' + item?.user?.id}>
                            <Button variant="contained" disableElevation className="btn btn-sm bg-info bg-opacity-10 text-primary"><strong>Reagendar?</strong>  <div className='p-1'></div> </Button>
                        </Link>

                    </div>
                </Typography>
            </DialogContent>

            <DialogActions>
                {!isMobile &&
                    <Button onClick={handleClose} style={{ color: "#f00" }}>Cancelar Pedido</Button>}
                {item?.accepted ?
                    <Button variant="contained" fullWidth={isMobile} className="d-flex gap-2 btn mainColor btn-round mb-0 text-center  p-2" style={{ minWidth: "10em" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"><i className="bi bi-camera-video"></i> <strong>Iniciar Aula</strong>
                    </Button> : isMine ? <Button fullWidth={isMobile} variant="contained" className="btn btn-sm mainBtn mainColor bg-opacity-10 text-white d-flex justify-content-center p-2" disableElevation onClick={sendApprove}>{loading ? <HashLoader color="#ffffff" size={20} loading /> : <>Aceitar</>}</Button> : <></>}
            </DialogActions>
        </div>
    </Dialog>;
}
